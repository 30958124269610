import { useSelector, useDispatch } from 'react-redux';
import * as searchActions from '../state/search';

const useSearch = () => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.search.query);
  const isOpen = useSelector((state) => state.search.isOpen);

  const setQuery = (value) => {
    return dispatch(searchActions.setQuery(value));
  };

  const openSearch = () => {
    return dispatch(searchActions.openSearch());
  };

  const closeSearch = () => {
    return dispatch(searchActions.closeSearch());
  };

  return {
      query,
      isOpen,
      setQuery,
      openSearch,
      closeSearch,
  };
}

export default useSearch;
