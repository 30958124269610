import { createAsyncThunk } from '@reduxjs/toolkit';

const removeFavorite = createAsyncThunk('favorites/removeFavorite', async (path) => {
  const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];
  const index = favorites.indexOf(path);

  if (index > -1) {
    favorites.splice(index, 1);
  }

  window.localStorage.setItem(
    'favorites',
    JSON.stringify(favorites),
  );
});

export default removeFavorite;
