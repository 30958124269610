import { DropdownMenu, Button } from '@radix-ui/themes';
import { CheckIcon } from '@radix-ui/react-icons';

import useModels from '../../../../../hooks/useModels';
import useEditor from '../../../../../renderer/hooks/useEditor';
import LoadingView from '../../../../feedback/spinners/LoadingView';
import Code from '../../../../data/Code';

const ModelFieldType = ({ options, value, onChange }) => {
  const { models } = useModels();
  const { dataConfig } = useEditor();

  const handleOnChange = (dataKey, field) => {
    let dataProps;

    if (options && options.dataProp) {
      dataProps = {
        [options.dataProp]: `${dataKey}.${field.name}`,
      };
    }

    onChange(field, dataProps);
  };

  if (!models) {
    return (
      <LoadingView text="Loading models..." />
    );
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger size="1">
        <Button variant="soft">
          <Code variant="ghost">{value.name}</Code>
          <DropdownMenu.TriggerIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content size="1">
        {Object.keys(dataConfig).map((dataKey) => {
          const modelId = dataConfig[dataKey].model;
          const model = models.find((model) => model.id === modelId);

          return (
            <DropdownMenu.Sub key={dataKey}>
              <DropdownMenu.SubTrigger>
                <Code variant="ghost">{dataKey}</Code>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                {model.fields.map((field) => (
                  <DropdownMenu.Item
                    key={field.name}
                    onClick={() => handleOnChange(dataKey, field)}
                  >
                    {field.name === value.name ? <CheckIcon /> : <div className="w-3" />}
                    <Code variant="ghost">{field.name}</Code>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ModelFieldType;
