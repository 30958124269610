import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, DropdownMenu, Tooltip } from '@radix-ui/themes';
import { DotsHorizontalIcon, Pencil1Icon } from '@radix-ui/react-icons';

import DisplayField from '../fields/DisplayField';

export default function SimpleList({ columns, items }) {
  const location = useLocation();
  const navigate = useNavigate();
  const thClassName = 'py-2 text-left text-sm font-medium text-gray-900 bg-white border-b-[0.5px] border-gray-200 z-50 sticky top-0';

  const handleOnClick = (item) => {
    navigate(`${location.pathname}/${item.id}`);
  };

  const handleOnClickEdit = (item) => {
    navigate(`${location.pathname}/${item.id}/update`);
  };

  if (!items) {
    return null;
  }

  return (
    <>
      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            {columns.map((column, index) => {
              let className;

              if (index === 0) {
                className = 'pl-4 pr-3 sm:pl-6 lg:pl-8';
              } else {
                className = 'px-3';
              }

              return (
                <th
                  key={column.name}
                  scope="col"
                  className={`${className} ${thClassName}`}
                >
                  {column.title}
                </th>
              );
            })}
            <th scope="col" className={`${thClassName} relative py-2 pl-3 pr-4 sm:pr-6`}>
              <span className="sr-only">Options</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {items.map((item) => {
            const tds = columns.map((column, colIndex) => {
              let className;
              
              if (colIndex === 0) {
                className = 'pr-3 pl-4 sm:pl-6 lg:pl-8';
              } else {
                className = 'px-3';
              }

              return (
                <td key={`item.id_${column.name}`} className={`${className} whitespace-nowrap border-b-[0.5px] border-gray-200 py-3 text-sm text-gray-500`}>
                  {colIndex === 0 ? (
                    <Link
                      to={`${location.pathname}/${item[column.name]}`}
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      {item[column.name]}
                    </Link>
                  ) : (
                    <DisplayField field={column} value={item[column.name]} />
                  )}
                </td>
              );
            });

            return (
              <tr key={item.id} onClick={() => handleOnClick(item)} className="bg-white hover:bg-gray-100">
                {tds}
                <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium pl-2 sm:pr-2 lg:pr-4 border-b-[0.5px] border-gray-200">
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                        <IconButton size="1" variant="ghost" color="gray" className="mr-1">
                          <Tooltip content="Options">
                            <DotsHorizontalIcon width="16" height="16" />
                          </Tooltip>
                        </IconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                      <DropdownMenu.Item onClick={() => handleOnClickEdit(item)}>
                        <Pencil1Icon /> Edit...
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {!items.length && (
        <div className="px-6 py-14 text-center sm:px-14 w-full bg-white">
          <p className="text-sm text-gray-900">
            No items found
          </p>
        </div>
      )}
    </>
  );
}
