import { useState, useImperativeHandle } from 'react';

const usePublishHandle = (forwardedRef) => {
  const [count, setCount] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useImperativeHandle(forwardedRef, () => ({
    publish: (options) => {
      setCount(count => count + 1);
      setTitle(options.title);
      setDescription(options.description);
    },
  }));

  return { count, title, description };
};

export default usePublishHandle;
