import AddNode from './actions/AddNode';

export default function TopSpaceBar() {
  return (
    <div className="absolute top-0 left-0 bottom-0 flex items-end justify-center p-4 z-100">
      <div className="w-full bg-background3 rounded-md">
        <AddNode />
      </div>
    </div>
  );
}
