import ComponentRenderer from '../../ComponentRenderer';

export default function TwoColumnFields({ fields }) {
  return (
    <dl className="grid grid-cols-1 sm:grid-cols-2">
      {fields.map((field, index) => (
        <ComponentRenderer
          key={index}
          componentConfig={field}
        />
      ))}
    </dl>
  )
}
