const generateListViewFromModel = (model) => {
  const modelName = model.id.split('.')[1];

  const columns = model.fields.map((field) => ({
    ...field,
    title: field.verbose_name,
  }));

  return [
    // Data Config
    {
      items: {
        model: model.id,
      },
    },

    // Component Config
    {
      name: 'lists/SimpleList',
      props: {
        columns,
      },
      dataProps: {
        items: 'items',
      },
    },
  ];
};

export default generateListViewFromModel;
