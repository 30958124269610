import ContentArea from '../../../components/editor/ContentArea';

export default function OneColumnFields({ uri, fields }) {
  return (
    <dl className="divide-y divide-gray-100">
      <ContentArea
        componentConfigs={fields}
        uri={`${uri}.fields`}
        emptyText="Add a field"
      />
    </dl>
  );
}
