const generateUpdateViewFromModel = (model, itemId) => {
  const modelName = model.id.split('.')[1];

  const fields = model.fields
    .map((field) => ({
      name: 'fields/FormFieldLabelLeft',
      props: { ...field },
    }));

  return [
    // Data Config
    {
      item: {
        model: model.id,
        getBy: { id: itemId },
      },
    },

    // Component Config
    {
      name: 'forms/Form',
      props: {
        model,
        content: {
          name: 'views/FullWidthView',
          props: {
            header: [{
              name: 'headers/HeaderWithActionsAndBreadcrumbs',
              props: {
                title: `Update ${modelName} ID ${itemId}`,
                buttons: [
                  { name: 'buttons/CancelEditButton', props: { modelId: model.id, itemId } },
                  { name: 'buttons/SubmitButton', props: { title: 'Save' } },
                ],
              },
            }],
            content: [{
              name: 'views/OneColumnFields',
              props: { fields },
            }],
          },
        },
      },
      dataProps: {
        initialValues: 'item',
      },
    },
  ];
};

export default generateUpdateViewFromModel;
