import { useState } from 'react';
import { PanelResizeHandle } from 'react-resizable-panels';

export default function ({ ...props }) {
  const [isDragging, setIsDragging] = useState(false);
  const className = isDragging ? 'w-[1px]' : 'w-[0.5px]';

  return (
    <PanelResizeHandle
      {...props}
      className={`${className} bg-separator1`}
      onDragging={setIsDragging}
    />
  );
}
