import { Code } from '@radix-ui/themes';
import CodeWithCopy from '../../data/CodeWithCopy';
import NodeIcon from '../icons/NodeIcon';

export default function NodeLabel({ node, className, color, withCopyButton = false }) {
  if (!node) return null;

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <NodeIcon node={node} />

      {withCopyButton ? (
        <CodeWithCopy
          variant="ghost"
          size="2"
          value={node.name}
          color={color}
        />
      ) : (
        <Code variant="ghost" size="2" color={color}>
          {node.name}
        </Code>
      )}
    </div>
  );
}
