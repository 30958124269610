import { createSlice } from '@reduxjs/toolkit';
import NetworkStatus from '../enums/NetworkStatus';
import { getFavorites, addFavorite, removeFavorite } from './actions';

const initialState = {
  status: NetworkStatus.IDLE,
  items: [],
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // getFavorites reducers.
      .addCase(getFavorites.pending, (state) => {
        state.status = NetworkStatus.PENDING;
      })
      .addCase(getFavorites.fulfilled, (state, action) => {
        state.status = NetworkStatus.FULFILLED;

        if (action.payload) {
          state.items = action.payload;
        } else {
          state.items = undefined;
        }
      })
      .addCase(getFavorites.rejected, (state) => {
        state.status = NetworkStatus.REJECTED;
        state.items = undefined;
      })

      // addFavorite reducers.
      .addCase(addFavorite.pending, (state, action) => {
        state.items.push(action.meta.arg);
      })

      // removeFavorite reducers.
      .addCase(removeFavorite.pending, (state, action) => {
        const index = state.items.indexOf(action.meta.arg);

        if (index > -1) {
          state.items.splice(index, 1);
        }
      })
  }
});

export default favoritesSlice;
