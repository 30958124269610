import { useState } from 'react';
import { DropdownMenu, IconButton, AlertDialog, Button, Flex, Code } from '@radix-ui/themes';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Hexagon } from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';

import NodeLabel from '../../../labels/NodeLabel';
import useGraph from '../../../../../hooks/graph/useGraph';
import useSelectedNode from '../../../../../hooks/graph/useSelectedNode';

export default function OptionsMenu() {
  const { deleteNode } = useGraph();
  const { selectedNode, selectNode } = useSelectedNode();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const showDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteNode = () => {
    selectNode(null);
    deleteNode(selectedNode);
    setIsDeleteDialogOpen(false);
  };

  useHotkeys('meta+backspace', showDeleteDialog , { preventDefault: true });

  return (
    <>
      <AlertDialog.Root open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Delete Node</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you want to delete this node?

            <NodeLabel
              node={selectedNode}
              className="p-2 mt-4 border-[0.5px] border-gray-600 rounded-md"
              color="red"
            />
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button variant="solid" color="red" onClick={handleDeleteNode}>
                Delete Node
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>

      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton size="1" variant="ghost" color="gray" className="mr-1">
            <DotsHorizontalIcon width="18" height="18" />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content size="1">
          <DropdownMenu.Item onClick={showDeleteDialog} color="red" shortcut="⌘⌫">
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}
