import { Badge, Link, Checkbox } from '@radix-ui/themes';
import Code from '../../../components/data/Code';
import CodeWithCopy from '../../../components/data/CodeWithCopy';
import RelationshipFieldCard from './RelationshipFieldCard';

function DisplayField({ field, value }) {
  const { name, type } = field;

  if (value === null) {
    return <Badge color="gray">N/A</Badge>;
  }

  if (name === 'id') {
    return <CodeWithCopy value={value} />;
  }

  if (type === 'number') {
    return <Code>{value}</Code>;
  }

  if (type === 'string') {
    return <span>{value}</span>;
  }

  if (type === 'email') {
    return (
      <Link href={`mailto:${value}`}>{value}</Link>
    );
  }

  if (type === 'boolean') {
    return (
      <Checkbox disabled defaultChecked={value} />
    );
  }

  if (type === 'date') {
    return <span>{new Date(value).toLocaleDateString()}</span>;
  }

  if (type === 'datetime') {
    return (
      <span>
        {new Date(value).toLocaleDateString()}
        {' '}
        {new Date(value).toLocaleTimeString()}
      </span>
    );
  }

  if (type === 'relationship') {
    return <RelationshipFieldCard field={field} value={value} />;
  }

  return <Badge color="gray">{value}</Badge>;
}

export default DisplayField;
