import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NetworkStatus from '../state/enums/NetworkStatus';
import { getModels } from '../state/models/actions';
import useSession from './useSession';

const useModels = () => {
  const dispatch = useDispatch();
  const { hasSession } = useSession();
  const status = useSelector((state) => state.models.status);
  const models = useSelector((state) => state.models.items);

  useEffect(() => {
    if (hasSession && status === NetworkStatus.IDLE) {
      dispatch(getModels());
    }
  }, [status, hasSession, dispatch]);

  return { models };
}

export default useModels;
