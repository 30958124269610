import { useEffect, useState } from 'react';
import { Card, Box, DataList } from '@radix-ui/themes';

import { data } from '../../../api';
import useModels from '../../../hooks/useModels';
import Alert from '../../../components/feedback/alerts/Alert';
import Spinner from '../../../components/feedback/spinners/Spinner';
import DisplayField from './DisplayField';

function RelationshipFieldCard({ field, value }) {
  const { models } = useModels();
  const [relatedItem, setRelatedItem] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const loadItem = async () => {
      const { items } = await data.model.list.v1({
          model_id: field.related_model,
      });

      return items.find(item => item.id === value);
    };

    if (field) {
      loadItem()
        .then((item) => {
          setRelatedItem(item);
        })
        .catch((error) => {
          console.error(`Error when loading related item '${field.name}':`, error);
          setError(error);
        });
    }
  }, [field]);

  if (error) {
    return (
      <Box maxWidth="450px">
        <Alert
          type={Alert.ERROR}
          title="Error when loading related item"
          message={error.message}
        />
      </Box>
    );
  }

  if (!relatedItem || !models) {
    return <Spinner />;
  }

  const relatedModel = models.find(model => model.id === field.related_model);

  return (
    <Box maxWidth="450px">
      <Card>
        <DataList.Root>
          {relatedModel.fields.map((modelField) => (
            <DataList.Item>
              <DataList.Label minWidth="88px">
                {modelField.verbose_name}
              </DataList.Label>
              <DataList.Value>
                <DisplayField 
                  field={modelField}
                  value={relatedItem[modelField.name]}
                />
              </DataList.Value>
            </DataList.Item>
          ))}
        </DataList.Root>
      </Card>
    </Box>
  );
}

export default RelationshipFieldCard;
