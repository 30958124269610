import HrefButton from "../../../components/buttons/HrefButton";

export default function CancelEditButton({ modelId, itemId }) {
  const parts = modelId.split('.');
  const detailPath = `/x/${parts[0]}/${parts[1].toLowerCase()}/${itemId}`;

  return (
    <HrefButton href={detailPath} variant="outline">
      Cancel
    </HrefButton>
  );
};
