import { useRef, useEffect, useState } from 'react';
import { ContextMenu } from '@radix-ui/themes';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import classNames from '../../utils/classNames';
import useEditor from '../../renderer/hooks/useEditor';
import RightClickMenu from './RightClickMenu';

export default function Draggable({ uri, children }) {
  const { removeComponent, editURI, uriToEdit } = useEditor();
  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false); 

  useEffect(() => {
    const element = ref.current;

    return draggable({
      getInitialData: () => ({ uri }),
      onDragStart: () => setIsDragging(true),
      onDrop: () => setIsDragging(false),
      element,
    });
  }, []);

  const className = classNames(
    'm-1 rounded overflow-hidden border border-dashed border-gray-300 hover:border-gray-500',
    uriToEdit === uri ? 'border-blue-500' : '',
    isDragging ? 'opacity-0' : '',
  );
  
  return (
    <ContextMenu.Root onOpenChange={() => console.log('right clicked')}>
      <ContextMenu.Trigger>
        <div ref={ref} className={className}>
          {children}
        </div>      
      </ContextMenu.Trigger>
      <RightClickMenu
        onDelete={() => removeComponent(uri)}
        onEdit={() => editURI(uri)}
      />
    </ContextMenu.Root>
  );
};
