import { useRef } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import { data } from '../../../api';
import Toast from '../../../components/feedback/toasts/Toast';
import ComponentRenderer from '../../ComponentRenderer';

export default function Form({ model, content, initialValues }) {
  const modelName = model.id.split('.')[1];
  const successToast = useRef();
  const errorToast = useRef();

  if (!initialValues) {
    initialValues = {};

    model.fields.forEach((field) => {
      if (field.name === 'id') {
        return;
      }

      switch (field.type) {
        case 'string':
          initialValues[field.name] = '';
          break;

        case 'number':
          initialValues[field.name] = 0;
          break;

        default:
          initialValues[field.name] = null;
      }
    });
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={values => {
          const errors = {};

          model.fields.forEach((field) => {
            if (field.name === 'id') {
              return;
            }

            if (field.required && !values[field.name]) {
              errors[field.name] = 'Required';
            }
          });

          return errors;
        }}
        onSubmit={async (values) => {
          try {
            if (values.id) {
              await data.model.update.v1({
                model_id: model.id,
                items: [
                  values,
                ]
              });
            } else {
              await data.model.create.v1({
                model_id: model.id,
                items: [
                  values,
                ]
              });
            }

            successToast.current.publish({
              title: 'Successfully saved!',
              description: `Your ${modelName} was successfully saved.`,
            });
          } catch (error) {
            errorToast.current.publish({
              title: `Error when saving ${modelName}`,
              description: error.message,
            });
          }
        }}
      >
        {() => (
          <FormikForm>
            <ComponentRenderer componentConfig={content} />
          </FormikForm>
        )}
      </Formik>

      <Toast type={Toast.SUCCESS} ref={successToast} />
      <Toast type={Toast.ERROR} ref={errorToast} />
    </>
  );
};
