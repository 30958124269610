import { createAsyncThunk } from '@reduxjs/toolkit';
import { data }  from '../../../api';

let promise;

const getModels = createAsyncThunk('models/getModels', async () => {
  if (promise) {
    return promise;
  }

  promise = data.model.schema.list.v1().finally(() => {
    promise = undefined;
  });

  return promise;
});

export default getModels;
