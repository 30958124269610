import AlertBase, { TITLE_COLORS, MESSAGE_COLORS } from './AlertBase';

const BTN_BG_COLORS = {
  [AlertBase.ERROR]: 'bg-red-50',
  [AlertBase.WARNING]: 'bg-yellow-50',
  [AlertBase.INFO]: 'bg-blue-50',
  [AlertBase.SUCCESS]: 'bg-green-50',
};

const BTN_HOVER_BG_COLORS = {
  [AlertBase.ERROR]: 'hover:bg-red-100',
  [AlertBase.WARNING]: 'hover:bg-yellow-100',
  [AlertBase.INFO]: 'hover:bg-blue-100',
  [AlertBase.SUCCESS]: 'hover:bg-green-100',
};

const BTN_TEXT_COLORS = {
  [AlertBase.ERROR]: 'text-red-800',
  [AlertBase.WARNING]: 'text-yellow-800',
  [AlertBase.INFO]: 'text-blue-800',
  [AlertBase.SUCCESS]: 'text-green-800',
};

const BTN_FOCUS_RING_COLORS = {
  [AlertBase.ERROR]: 'focus:ring-red-600',
  [AlertBase.WARNING]: 'focus:ring-yellow-600',
  [AlertBase.INFO]: 'focus:ring-blue-600',
  [AlertBase.SUCCESS]: 'focus:ring-green-600',
};

const BTN_FOCUS_RING_OFFSET_COLORS = {
  [AlertBase.ERROR]: 'focus:ring-offset-red-50',
  [AlertBase.WARNING]: 'focus:ring-offset-yellow-50',
  [AlertBase.INFO]: 'focus:ring-offset-blue-50',
  [AlertBase.SUCCESS]: 'focus:ring-offset-green-50',
};

function AlertWithButtons({ type, title, message, buttons }) {
  return (
    <AlertBase type={type}>
      <div className="ml-3">
        <h3 className={`text-sm font-medium ${TITLE_COLORS[type]}`}>
          {title}
        </h3>
        <div className={`mt-2 text-sm ${MESSAGE_COLORS[type]}`}>
          {message}
        </div>
        <div className="mt-4">
          <div className="-mx-2 -my-1.5 flex">
            {buttons.map((btn) => (
              <button
                key={btn.title}
                type="button"
                onClick={btn.onClick}
                className={`rounded-md ${BTN_BG_COLORS[type]} px-2 py-1.5 text-sm font-medium ${BTN_TEXT_COLORS[type]} ${BTN_HOVER_BG_COLORS[type]} focus:outline-none focus:ring-2 ${BTN_FOCUS_RING_COLORS[type]} focus:ring-offset-2 ${BTN_FOCUS_RING_OFFSET_COLORS[type]}`}
              >
                {btn.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </AlertBase>
  );
};

AlertWithButtons.ERROR = AlertBase.ERROR;
AlertWithButtons.WARNING = AlertBase.WARNING;
AlertWithButtons.INFO = AlertBase.INFO;
AlertWithButtons.SUCCESS = AlertBase.SUCCESS;

export default AlertWithButtons;
