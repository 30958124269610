import { DataList, Badge } from '@radix-ui/themes';

export default function Properties({ selectedNode }) {
  return (
    <DataList.Root size="1" className="w-full">
      {Object.keys(selectedNode)
        .filter(
          (key) =>
            typeof selectedNode[key] === "string" ||
            typeof selectedNode[key] === "number"
        )
        .map((key) => (
          <DataList.Item align="center" key={key} className="w-full">
            <DataList.Label minWidth="88px">{key}</DataList.Label>
            <DataList.Value className="justify-end">
              <Badge color="jade" variant="soft">
                {selectedNode[key]}
              </Badge>
            </DataList.Value>
          </DataList.Item>
        ))}
    </DataList.Root>
  );
}
