import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  query: '',
  isOpen: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery(state, action) {
      state.query = action.payload;
    },
    openSearch(state) {
      state.isOpen = true;
    },
    closeSearch(state) {
      state.isOpen = false;
    },
  },
});

export const { setQuery, openSearch, closeSearch } = searchSlice.actions;
export default searchSlice;
