import * as RadixThemes from '@radix-ui/themes';

const Button = ({ className, children, variant = 'solid', ...props }) => {
  return (
    <RadixThemes.Button
      className={className}
      variant={variant}
      {...props}
    >
      {children}
    </RadixThemes.Button>
  );
};

export default Button;
