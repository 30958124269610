import { useRef } from 'react';
import { TextField, Code } from '@radix-ui/themes';
import { SearchIcon } from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';

export default function TopSpaceBar({ onSearch }) {
  const inputRef = useRef(null);

  useHotkeys('meta+f', () => inputRef.current.focus(), { preventDefault: true });

  return (
    <div className="absolute top-0 left-0 right-0 flex items-center justify-end p-2 z-100">
      <TextField.Root variant="soft" color="gray" placeholder="Filter…" ref={inputRef} onKeyDown={onSearch}>
        <TextField.Slot>
          <SearchIcon size={16} />
        </TextField.Slot>
        <TextField.Slot>
          <Code color="gray" size="1">⌘F</Code>
        </TextField.Slot>
      </TextField.Root>
    </div>
  );
}
