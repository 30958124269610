import { createSlice } from '@reduxjs/toolkit';
import NetworkStatus from '../enums/NetworkStatus';
import { getModels } from './actions';

const initialState = {
  status: NetworkStatus.IDLE,
  items: undefined,
};

const modelsSlice = createSlice({
  name: 'models',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // getModels reducers.
      .addCase(getModels.pending, (state) => {
        state.status = NetworkStatus.PENDING;
      })
      .addCase(getModels.fulfilled, (state, action) => {
        state.status = NetworkStatus.FULFILLED;

        if (action.payload) {
          state.items = action.payload.items;
        } else {
          state.items = undefined;
        }
      })
      .addCase(getModels.rejected, (state) => {
        state.status = NetworkStatus.REJECTED;
        state.items = undefined;
      })
  }
});

export default modelsSlice;
