import { useFormikContext } from 'formik';
import Button from '../../../components/buttons/Button';

export default function SubmitButton({ title = 'Submit' }) {
  const formik = useFormikContext();

  return (
    <Button type="submit" loading={formik.isSubmitting}>
      {title}
    </Button>
  );
};
