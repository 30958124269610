import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NetworkStatus from '../state/enums/NetworkStatus';
import * as actions from '../state/favorites';
import useSession from './useSession';

const useFavorites = () => {
  const dispatch = useDispatch();
  const { hasSession } = useSession();
  const status = useSelector((state) => state.favorites.status);
  const favorites = useSelector((state) => state.favorites.items);

  useEffect(() => {
    if (hasSession && status === NetworkStatus.IDLE) {
      dispatch(actions.getFavorites());
    }
  }, [status, hasSession, dispatch]);

  const addFavorite = async (path) => {
    // Make sure favorites have loaded before adding more.
    await dispatch(actions.getFavorites());
    await dispatch(actions.addFavorite(path));
  };

  const removeFavorite = async (path) => {
    // Make sure favorites have loaded before removing.
    await dispatch(actions.getFavorites());
    await dispatch(actions.removeFavorite(path));
  };

  const isFavorite = (path) => {
    return favorites.indexOf(path) > -1;
  };

  return {
    favorites,
    addFavorite,
    removeFavorite,
    isFavorite,
  };
};

export default useFavorites;
