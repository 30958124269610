import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addNode, deleteNode, removeRelation } from '../../state/graph/slice';

const getLinks = (nodes) => {
  const links = [];

  nodes.forEach((node) => {
    node.relations.forEach((relation) => {
      links.push({
        source: node.id,
        target: relation,
      });
    });
  });

  return links;
};

const useGraph = () => {
  const dispatch = useDispatch();
  const nodes = useSelector((state) => state.graph.nodes);
  const links = useMemo(() => getLinks(nodes), [nodes]);

  return {
    nodes,
    links,
    addNode: (node) => {
      dispatch(addNode(node));
    },
    deleteNode: (node) => {
      dispatch(deleteNode(node));
    },
    removeRelation: (sourceId, targetId) => {
      dispatch(removeRelation({ sourceId, targetId }));
    },
  };
};

export default useGraph;
