import ContentArea from '../../../components/editor/ContentArea';

export default function HeaderWithActionsAndBreadcrumbs({ uri, title, buttons }) {
  return (
    <div>
      <div className="mt-4 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {title || '[No Title]'}
          </h2>
        </div>
        <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0 gap-x-2">
          <ContentArea
            componentConfigs={buttons}
            uri={`${uri}.buttons`}
            emptyText="Add a button"
          />
        </div>
      </div>
    </div>
  )
};
