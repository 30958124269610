import { useState, useEffect } from 'react';
import { Dialog, Button, Flex, Tooltip, Text, TextField } from '@radix-ui/themes';
import { PlusIcon } from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';

import useGraph from '../../../../hooks/graph/useGraph';
import useSelectedNode from '../../../../hooks/graph/useSelectedNode';

export default function AddNode() {
  const { selectedNode, selectNode } = useSelectedNode();
  const [isOpen, setIsOpen] = useState(false);
  const { addNode } = useGraph();
  const [name, setName] = useState('');
  const [dependencies, setDependencies] = useState('');

  useEffect(() => {
    if (selectedNode) {
      const defaultName = `${selectedNode.name.split('.').slice(0, -1).join('.')}.untitled`;
      const defaultDependency = selectedNode.id;

      setName(defaultName);
      setDependencies(defaultDependency);
    }
  }, [selectedNode]);

  const handleAddNode = () => {
    const newName = name || defaultName;
    const newDependencies = dependencies || defaultDependency;
    const id = `${newName.replace(/\./g, '-')}-1`;

    const newNode = {
      id,
      name: newName,
      version: '1.0.0',
      type: 'function.v1.0.0',
      data: {},
      relations: newDependencies.split(',').map((dependency) => dependency.trim()),
      created_at: new Date().toISOString(),
    };

    addNode(newNode);
    selectNode(newNode);

    setName('');
    setDependencies('');
    setIsOpen(false);
  };

  useHotkeys('ctrl+n', () => setIsOpen(true), { preventDefault: true });

  return (
    <>
      <Tooltip content="Add Node ^N">
        <button className="p-4 hover:bg-background2 rounded-md" onClick={() => setIsOpen(true)}>
          <PlusIcon size={16} />
        </button>
      </Tooltip>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>Add Node</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Add a new node to the system.
          </Dialog.Description>

          <Flex direction="column" gap="3">
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Node Name
              </Text>
              <TextField.Root
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="e.g. system.node.name"
              />
            </label>
            <label>
              <Text as="div" size="2" mb="1" weight="bold">
                Dependencies
              </Text>
              <TextField.Root
                value={dependencies}
                onChange={(event) => setDependencies(event.target.value)}
                placeholder="e.g. system-node-name-1"
              />
            </label>
          </Flex>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button color="lime" onClick={handleAddNode}>
              Add Node
            </Button>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}
