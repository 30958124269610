import AlertBase, { TITLE_COLORS, MESSAGE_COLORS } from './AlertBase';

function Alert({ type, title, message }) {
  return (
    <AlertBase type={type}>
      <div className="ml-3">
        <h3 className={`text-sm font-medium ${TITLE_COLORS[type]}`}>
          { title }
        </h3>
        <div className={`mt-2 text-sm ${MESSAGE_COLORS[type]}`}>
          { message }
        </div>
      </div>
    </AlertBase>
  );
};

Alert.ERROR = AlertBase.ERROR;
Alert.WARNING = AlertBase.WARNING;
Alert.INFO = AlertBase.INFO;
Alert.SUCCESS = AlertBase.SUCCESS;

export default Alert;
