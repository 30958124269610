import { Tabs, Box, Text } from '@radix-ui/themes';
import PropertiesTab from './tabs/PropertiesTab';

const EditorPanel = () => {
  return (
    <Tabs.Root defaultValue="properties" className="w-full">
      <Tabs.List>
        <Tabs.Trigger value="properties">Properties</Tabs.Trigger>
        <Tabs.Trigger value="data">Data</Tabs.Trigger>
        <Tabs.Trigger value="settings">Settings</Tabs.Trigger>
      </Tabs.List>

      <Box p="3">
        <Tabs.Content value="properties">
          <PropertiesTab />
        </Tabs.Content>

        <Tabs.Content value="data">
          <Text size="2">Configure data dependencies here.</Text>
        </Tabs.Content>

        <Tabs.Content value="settings">
          <Text size="2">Adjust panel settings here.</Text>
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  );
};

export default EditorPanel;
