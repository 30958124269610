import components from '../../../../renderer/components';
import ComponentCard from './ComponentCard';

const Components = ({ onClickComponent }) => {
  const componentNames = Object.keys(components);

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {componentNames.map((componentName) => (
        <ComponentCard
          key={componentName}
          name={componentName}
          onClick={() => onClickComponent(componentName)}
        />
      ))}
    </div>
  );
};

export default Components;
