import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NetworkStatus from '../state/enums/NetworkStatus';

import {
  getCurrentSession,
  endCurrentSession,
  loginWithUsernamePassword,
} from '../state/auth/actions';

const useSession = () => {
  const dispatch = useDispatch();
  const hasSession = useSelector((state) => state.auth.hasSession);
  const status = useSelector((state) => state.auth.status);
  const user = useSelector((state) => state.auth.user);

  const login = (username, password) => {
    return dispatch(loginWithUsernamePassword({
      username, password,
    }));
  };

  const logout = () => {
    return dispatch(endCurrentSession());
  };

  useEffect(() => {
    if (status === NetworkStatus.IDLE) {
      dispatch(getCurrentSession());
    }
  }, [status, dispatch]);

  return {
      hasSession,
      user,
      status,
      login,
      logout,
  };
}

export default useSession;
