import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import Components from './Components';
import useViewContext from '../../../../renderer/hooks/useViewContext';

const AddComponentDialog = ({ uri, title, open, onOpenChange }) => {
  const { addComponent } = useViewContext();

  const handleClickComponent = (componentName) => {
    const initialComponentConfig = {
      name: componentName,
      props: {},
    };

    addComponent(uri, initialComponentConfig);
    onOpenChange(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[850px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="mb-4 text-[17px] font-medium">
            {title}
          </Dialog.Title>

          <Components onClickComponent={handleClickComponent} />

          <Dialog.Close asChild>
            <button
              className="text-black hover:bg-gray-100 focus:shadow-gray-100 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AddComponentDialog;
