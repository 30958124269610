const ComponentCard = ({ name, onClick }) => (
  <div
    onClick={onClick}
    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
  >
    <div className="min-w-0 flex-1">
      <a href="#" className="focus:outline-none">
        <p className="text-sm font-medium text-gray-900">{name}</p>
      </a>
    </div>
  </div>
);

export default ComponentCard;
