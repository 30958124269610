import HrefButton from '../../../components/buttons/HrefButton';

export default function CreateButton({ modelId }) {
  if (!modelId) {
    console.warn('CreateButton is missing modelId');

    return (
      <HrefButton href="">Create</HrefButton>
    );
  }

  const parts = modelId.split('.');
  const createPath = `/x/${parts[0]}/${parts[1].toLowerCase()}/create`;

  return (
    <HrefButton href={createPath}>
      Create
    </HrefButton>
  );
};
