import { useEffect, useState } from 'react';
import { data } from '../api';
import LoadingView from '../components/feedback/spinners/LoadingView';
import ViewContext from './contexts/ViewContext';
import ComponentRenderer from './ComponentRenderer';

const getBy = (items, field, value) => {
  return items.find(item => {
    return item[field] === value;
  });
};

const loadViewData = async (queries) => {
  const keys = Object.keys(queries);
  const viewData = {};

  await Promise.all(keys.map(async key => {
    const query = queries[key];
    const { items } = await data.model.list.v1({
        model_id: query.model,
    });

    if (query.getBy) {
      const getByField = Object.keys(query.getBy)[0];
      const getByValue = query.getBy[getByField];

      viewData[key] = getBy(items, getByField, getByValue);
    } else {
      viewData[key] = items;
    }
  }));

  return viewData;
};

function ViewRenderer({ dataConfig, componentConfig, editMode = false }) {
  const [viewData, setViewData] = useState();

  useEffect(() => {
    if (dataConfig) {
      loadViewData(dataConfig).then((data) => {
        setViewData(data);
      });
    }
  }, [dataConfig]);

  if (!viewData) {
    return <LoadingView text="Loading data..." />;
  }

  const viewContext = {
    viewData,
    editMode,
  };

  return (
    <ViewContext.Provider value={viewContext}>
      <div className="flex-1">
        <ComponentRenderer
          uri={componentConfig.name}
          componentConfig={componentConfig}
        />
      </div>
    </ViewContext.Provider>
  );
}

export default ViewRenderer;
