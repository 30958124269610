import { combineReducers } from 'redux';
import auth from './auth';
import models from './models';
import search from './search';
import favorites from './favorites';
import graph from './graph';

const reducer = combineReducers({
    auth,
    models,
    search,
    favorites,
    graph,
});

export default reducer;
