import ContentArea from '../../../components/editor/ContentArea';

export default function FullWidthView({ header, content, uri }) {
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full">
      <ContentArea
        componentConfigs={header}
        uri={`${uri}.header`}
        emptyText="Add a header"
      />

      <div className="mt-8 flow-root">
        <div className="-my-2">
          <div className="min-w-full py-2 align-middle">
            <ContentArea
              componentConfigs={content}
              uri={`${uri}.content`}
              emptyText="Add a list"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
