import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import useModels from "../hooks/useModels";

function ModelsPage() {
  const { models } = useModels();

  return (
      <div className="relative w-full">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Model
              </th>
              <th scope="col" className="px-6 py-3 text-right"></th>
            </tr>
          </thead>
          <tbody>
            {models && models.map((schema, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {schema.id}
                </th>
                <td className="px-6 py-4 text-right flex justify-end">
                  <Cog6ToothIcon
                    className="text-gray-400 group-hover:text-indigo-600 h-6 w-6 shrink-0"
                    aria-hidden="true"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  );
}

export default ModelsPage;
