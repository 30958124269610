import { useState, useEffect, useRef, useCallback } from 'react';
import * as d3 from 'd3';

import useGraph from '../../../hooks/graph/useGraph';

const margin = 1; // to avoid clipping the root circle stroke

function transformToHierarchy(inputArray) {
  const root = { name: "MyApp", children: [] };

  function addToHierarchy(node, nameParts, item) {
    if (nameParts.length === 0) return;

    let child = node.children.find(c => c.name === nameParts[0]);
    if (!child) {
      child = { name: nameParts[0], children: [], value: item.relations.length };
      node.children.push(child);
    }

    addToHierarchy(child, nameParts.slice(1), item);
  }

  inputArray.forEach(item => {
    const nameParts = item.name.split('.');
    addToHierarchy(root, nameParts, item);
  });

  return root;
}

export default function CircleDiagram() {
  const svgRef = useRef();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { nodes } = useGraph();

  const containerRef = useCallback(node => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => { 
      const { width, height } = node.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    if (width && height && nodes.length > 0) {
      const svg = d3.select(svgRef.current);
      svg.selectAll('*').remove();

      const hierarchyData = transformToHierarchy(nodes);
      console.log(hierarchyData);

      // Specify the number format for values.
      const format = d3.format(",d");

      // Create the pack layout.
      const pack = d3.pack()
          .size([width - margin * 2, height - margin * 2])
          .padding(3);

      // Compute the hierarchy from the JSON data; recursively sum the
      // values for each node; sort the tree by descending value; lastly
      // apply the pack layout.
      const root = pack(d3.hierarchy(hierarchyData)
          .sum(d => d.value)
          .sort((a, b) => b.value - a.value));

      // Create the SVG container.
      const container = svg
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [-margin, -margin, width, height])
          .attr("style", "width: 100%; height: auto; font: 10px Inter, sans-serif;")
          .attr("text-anchor", "middle")
          .call(d3.zoom().on("zoom", zoomed))
        .append("g");

      function zoomed(event) {
        container.attr("transform", event.transform);
      }

      // Place each node according to the layout’s x and y values.
      const node = container.append("g")
        .selectAll()
        .data(root.descendants())
        .join("g")
          .attr("transform", d => `translate(${d.x},${d.y})`);

      // Add a title.
      node.append("title")
          .text(d => `${d.ancestors().map(d => d.data.name).reverse().join(".")}`);

      // Add a filled or stroked circle.
      node.append("circle")
          .attr("fill", d => d.children ? "transparent" : "#BDEE63")
          .attr("stroke", d => d.children ? "#25282D" : null)
          .attr("r", d => d.r)
          .style("cursor", d => d.children ? "default" : "pointer");

      // Add curved text labels for non-leaf nodes.
      const nonLeafNodes = node.filter(d => d.children?.length > 0);

      nonLeafNodes.append("path")
        .attr("id", (d, i) => `circlePath-${i}`)
        .attr("d", d => `M ${-d.r}, 0 a ${d.r},${d.r} 0 1,1 ${d.r * 2},0`)
        .style("fill", "none")
        .style("stroke", "none");

      nonLeafNodes.append("text")
        .append("textPath")
        .attr("xlink:href", (d, i) => `#circlePath-${i}`)
        .attr("startOffset", "60%")
        .style("font-size", d => `${Math.max(8, Math.min(d.r / 3, 16))}px`)
        .style("fill", "#AFB4BA")
        .text(d => d.data.name);

      // Add a label to leaf nodes.
      const leafNodes = node.filter(d => !d.children && d.r > 10);
      
      leafNodes.append("text")
        .attr("clip-path", d => `circle(${d.r})`)
        .append("tspan")
          .style("cursor", "pointer")
          .attr("x", 0)
          .attr("y", 3)
          .text(d => d.data.name);
    }
  }, [width, height, nodes]);

  return (
    <div
      ref={containerRef}
      className="w-full h-full relative"
    >
      <svg ref={svgRef} />
    </div>
  )
}
