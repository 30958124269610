import { useLocation } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { IconButton, Tooltip } from '@radix-ui/themes';
import { PlusIcon } from '@radix-ui/react-icons';

import LogoSmall from '../logos/LogoSmall';
import Link from './Link';

const Breadcrumb = ({ breadcrumb, index }) => {
  if (breadcrumb === 'X') {
    return (
      <li>
        <Link to="/">
          <LogoSmall className="h-6 w-auto" />
        </Link>
      </li>
    );
  }

  return (
    <li>
      <div className={'flex ' + (index > 0 ? 'items-center' : '')}>
        {index > 0 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
        <a href="#" className={'text-sm font-medium text-gray-500 hover:text-gray-700 ' +  (index > 0 ? 'ml-2' : '')}>
          {breadcrumb}
        </a>
      </div>
    </li>
  );
};

const Breadcrumbs = () => {
  const location = useLocation();

  const breadcrumbs = location.pathname
    .split('/')
    .map((segment) => {
      if (segment) {
        return segment[0].toUpperCase() + segment.substring(1);
      }
    });

  breadcrumbs.shift();

  return (
    <div className="flex items-center gap-3">
      <nav aria-label="Breadcrumbs">
        <ol role="list" className="flex items-center space-x-2">
          {breadcrumbs.map((breadcrumb, index) => (
            <Breadcrumb
              key={breadcrumb}
              breadcrumb={breadcrumb}
              index={index}
            />
          ))}
        </ol>
      </nav>
      <Tooltip content={`Create ${breadcrumbs[breadcrumbs.length - 1]}`}>
        <Link to={`${location.pathname}/create`} className="flex items-center">
          <IconButton size="1" variant="ghost" color="gray">
            <PlusIcon width="16" height="16" />
          </IconButton>
        </Link>
      </Tooltip>
    </div>
  );
};

export default Breadcrumbs;
