import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Input from '../components/form/Input';
import Label from '../components/form/Label';
import Button from '../components/buttons/Button';
import Alert from '../components/feedback/alerts/Alert';
import PoweredBy from '../components/logos/PoweredBy';
import useSession from '../hooks/useSession';

export default function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const { login } = useSession();

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await login(username, password);

      if (result.error) {
        throw result.error;
      }

      navigate('/');
    } catch (error) {
      console.error('Error when logging in:', error);
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {error && (
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <Alert
              type={Alert.ERROR}
              title="Unable to sign in"
              message={error.message}
            />
          </div>
        )}

        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <Label htmlFor="username">Username</Label>
              <div className="mt-2">
                <Input
                  id="username"
                  name="username"
                  type="text"
                  value={username}
                  onChange={event => setUsername(event.target.value)}
                  autoFocus
                  required
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <Label htmlFor="password">Password</Label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  required
                />
              </div>
            </div>

            <div>
              <Button type="submit" className="w-full" loading={isSubmitting}>
                Sign in
              </Button>
            </div>
          </form>

          <PoweredBy className="flex items-center place-content-center mt-2" />
        </div>
      </div>
    </>
  )
};
