const generateCreateViewFromModel = (model) => {
  const modelName = model.id.split('.')[1];

  const fields = model.fields
    .filter((field) => field.name !== 'id')
    .map((field) => ({
      name: 'fields/FormFieldLabelLeft',
      props: { ...field },
    }));

  return [
    // Data Config
    {
    },

    // Component Config
    {
      name: 'forms/Form',
      props: {
        model,
        content: {
          name: 'views/FullWidthView',
          props: {
            header: [{
              name: 'headers/HeaderWithActionsAndBreadcrumbs',
              props: {
                title: ' ',
                buttons: [
                  { name: 'buttons/SubmitButton', props: { title: 'Save' } },
                ],
              },
            }],
            content: [{
              name: 'views/OneColumnFields',
              props: { fields },
            }],
          },
        },
      },
    },
  ];
};

export default generateCreateViewFromModel;
