import { useRef, useState, useEffect } from 'react';
import { dropTargetForElements, monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import classNames from '../../utils/classNames';
import Empty from './Empty';

export default function Droppable({
  uri,
  className,
  isDraggingClassName,
  isOverClassName,
  isEmpty,
  emptyText,
  onDrop,
  children,
  ...props
}) {
  const ref = useRef(null);
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    const element = ref.current;

    return dropTargetForElements({
      element,
      getData: () => ({ uri }),
      onDragEnter: () => setIsOver(true),
      onDragLeave: () => setIsOver(false),
      onDrop: () => setIsOver(false),
    });
  }, []);

  useEffect(() => {
    return monitorForElements({
      onDrop({ source, location }) {
        const destination = location.current.dropTargets[0];

        if (!destination) {
          return;
        }

        const sourceURI = source.data.uri;
        const destinationURI = destination.data.uri;

        if (destinationURI === uri) {
          onDrop && onDrop({ sourceURI, destinationURI });
        }
      },
    });
  }, [uri, onDrop]);

  const classes = classNames(
    className,
    isOver ? isOverClassName : '',
  );

  return (
    <div
      ref={ref}
      className={classes}
      {...props}
    >
      {isEmpty ? (
        <Empty uri={uri} text={emptyText} />
      ) : (
        children
      )}
    </div>
  );
};
