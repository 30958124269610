import { Panel, PanelGroup, PanelResizeHandle } from '../components/layout';
import NodePanel from '../components/space/panels/NodePanel';
import ForceGraph from '../components/space/visualizations/ForceGraph';

export default function SpacePage() {
  return (
    <div className="h-full w-full">
      <PanelGroup autoSaveId="root" direction="horizontal">
        <Panel tagName='main' className="bg-black relative">
          <ForceGraph />
        </Panel>
        <PanelResizeHandle />
        <Panel defaultSize={25} minSize={20} maxSize={50}>
          <div className="h-full overflow-auto">
            <NodePanel />
          </div>
        </Panel>
      </PanelGroup>
    </div>
  );
}
