import { useState } from 'react';
import EmptyStateDashed from '../feedback/empty/EmptyStateDashed';
import AddComponentDialog from './dialogs/AddComponentDialog';

export default function Empty({ uri, text }) {
  const [showAddComponentDialog, setShowAddComponentDialog] = useState(false);

  const handleClick = () => {
    setShowAddComponentDialog(true);
  };

  return (
    <>
      <EmptyStateDashed
        title={text}
        onClick={handleClick}
      />

      <AddComponentDialog
        uri={uri}
        title={text}
        open={showAddComponentDialog}
        onOpenChange={setShowAddComponentDialog}
      />
    </>
  );
};
