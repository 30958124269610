import { Link, useLocation } from 'react-router-dom';
import DisplayField from '../fields/DisplayField';
import HrefButton from '../../../components/buttons/HrefButton';

export default function ListInCard({ columns, items }) {
  const location = useLocation();

  if (!items) {
    return null;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column, index) => {
                      let className;

                      if (index === 0) {
                        className = 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8';
                      } else {
                        className = 'px-3 py-3.5 text-left text-sm font-semibold text-gray-900';
                      }

                      return (
                        <th key={column.name} scope="col" className={className}>
                          {column.title}
                        </th>
                      );
                    })}
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {items.map((item) => {
                    const tds = columns.map((column, colIndex) => {
                      let className;
                      
                      if (colIndex === 0) {
                        className = 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8';
                      } else {
                        className = 'whitespace-nowrap px-3 py-4 text-sm text-gray-500';
                      }

                      return (
                        <td key={`item.id_${column.name}`} className={className}>
                          {colIndex === 0 ? (
                            <Link
                              to={`${location.pathname}/${item[column.name]}`}
                              className="font-semibold text-indigo-600 hover:text-indigo-500"
                            >
                              {item[column.name]}
                            </Link>
                          ) : (
                            <DisplayField field={column} value={item[column.name]} />
                          )}
                        </td>
                      );
                    });

                    return (
                      <tr key={item.id}>
                        {tds}
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <HrefButton
                            href={`${location.pathname}/${item.id}/update`}
                            variant="ghost"
                          >
                            Edit
                          </HrefButton>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {!items.length && (
                <div className="px-6 py-14 text-center sm:px-14 w-full bg-white">
                  <p className="text-sm text-gray-900">
                    No items found
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
