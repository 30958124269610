import { Badge, Text, IconButton } from '@radix-ui/themes';
import { CircleOffIcon, Trash2Icon } from 'lucide-react';
import NodeLabelLink from '../labels/NodeLabelLink';

export default function NodeList({ nodes, noNodesText, canDelete = false, onDelete }) {
  if (!nodes.length && noNodesText) {
    return (
      <div className="w-full p-5">
        <div className="flex justify-center items-center gap-2">
          <CircleOffIcon size={12} color="gray" />
          <Text size="1" color="gray">{noNodesText}</Text>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      {nodes.map((node) => (
        <div key={node.id} className="flex items-center justify-between border-b-[0.5px] border-b-separator1 p-1 px-2 w-full">
          <div className="flex-shrink overflow-hidden">
            <NodeLabelLink node={node} />
          </div>
          <div className="flex items-center gap-1">
            <Badge color="gray" variant="soft" size="1">
              {node.version}
            </Badge>
            <Badge color="orange" variant="soft" size="1">
              {node.type}
            </Badge>
            {canDelete && (
              <IconButton
                size="1"
                variant="ghost"
                color="red"
                onClick={() => onDelete(node)}
              >
                <Trash2Icon size={12} />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
