import useViewContext from '../../renderer/hooks/useViewContext';
import useEditor from '../../renderer/hooks/useEditor';
import ComponentRenderer from '../../renderer/ComponentRenderer';
import Droppable from './Droppable';

export default function ContentArea({ componentConfigs, uri, emptyText }) {
  const { editMode } = useViewContext();
  const { getConfigByURI, addComponent, removeComponent } = useEditor();

  const renderComponents = () => {
    if (Array.isArray(componentConfigs)) {
      return componentConfigs.map((config, index) => (
        <ComponentRenderer
          componentConfig={config}
          key={`${uri}.#${index}`}
          uri={`${uri}.#${index}`}
        />
      ));
    }

    return (
      <ComponentRenderer
        componentConfig={componentConfigs}
        uri={`${uri}.#0`}
      />
    );
  };

  const handleDrop = ({ sourceURI, destinationURI }) => {
    if (sourceURI === destinationURI) {
      return;
    }

    if (!sourceURI || !destinationURI) {
      return;
    }

    const activeConfig = getConfigByURI(sourceURI);

    removeComponent(sourceURI);
    addComponent(destinationURI, activeConfig);
  };

  const componentCount = componentConfigs ? componentConfigs.length : 0;
  const isEmpty = !componentCount;

  if (!editMode) {
    return renderComponents();
  }

  return (
    <Droppable
      uri={uri}
      isDraggingClassName="bg-gray-100"
      isOverClassName="bg-green-300"
      isEmpty={isEmpty}
      emptyText={emptyText}
      onDrop={handleDrop}
    >
      {renderComponents()}
    </Droppable>
  );
};
