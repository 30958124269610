import { DataList } from '@radix-ui/themes';
import useEditor from '../../../../renderer/hooks/useEditor';
import components from '../../../../renderer/components';
import propertyTypes from './propertyTypes';

const PropertiesTab = () => {
  const {
    uriToEdit,
    getConfigByURI,
    updateComponentProp,
    updateComponentDataProps,
  } = useEditor();

  if (!uriToEdit) {
    return (
      <div className="flex-1 self-center justify-self-center text-center">
        <span className="block text-sm text-gray-500">
          Click on a component to edit.
        </span>
      </div>
    );
  }

  const config = uriToEdit ? getConfigByURI(uriToEdit) : null;
  const component = config ? components[config.name] : null;
  const editableProps = component ? component.editableProps : null;

  const handleOnPropertyValueChange = (propertyName, newValue, dataProps) => {
    updateComponentProp(uriToEdit, propertyName, newValue);

    if (dataProps) {
      updateComponentDataProps(uriToEdit, dataProps);
    }
  };

  return (
    <div className="flex-1 pt-2">
      <DataList.Root>
        {Object.keys(editableProps).map((propertyName) => {
          const property = editableProps[propertyName];
          const TypeComponent = propertyTypes[property.type];

          return (
            <DataList.Item key={propertyName} align="center">
              <DataList.Label minWidth="88px">
                {property.title || propertyName}
              </DataList.Label>
              <DataList.Value>
                {TypeComponent && (
                  <TypeComponent
                    value={config.props[propertyName]}
                    options={property.options}
                    onChange={(newValue, dataProps) => (
                      handleOnPropertyValueChange(propertyName, newValue, dataProps)
                    )}
                  />
                )}
              </DataList.Value>
            </DataList.Item>
          );
        })}
      </DataList.Root>
    </div>
  );
};

export default PropertiesTab;
