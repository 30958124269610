import useViewContext from './hooks/useViewContext';
import Draggable from '../components/editor/Draggable';
import components from './components';

const getDataFromViewData = (query, viewData) => {
  const keys = query.split('.');

  return keys.reduce((result, key) => {
    return result && result[key];
  }, viewData);
};

const loadComponentData = (dataProps, viewData) => {
  const componentData = {};

  if (!dataProps) {
    return componentData;
  }

  const keys = Object.keys(dataProps);

  keys.forEach(key => {
    const query = dataProps[key];
    componentData[key] = getDataFromViewData(query, viewData);
  });

  return componentData;
};

function ComponentRenderer({ componentConfig, uri, forceReadOnlyMode }) {
  const { viewData, editMode } = useViewContext();

  if (!componentConfig) {
    return null;
  }

  const Component = components[componentConfig.name];

  const componentData = loadComponentData(
    componentConfig.dataProps,
    viewData,
  );

  const renderComponent = () => (
    <Component
      {...componentConfig.props}
      {...componentData}
      uri={uri}
      editMode={editMode}
    />
  );

  if (!Component) {
    throw new Error(`Component with name ${componentConfig.name} does not exist`);
  }

  if (editMode && !forceReadOnlyMode && !componentConfig.name.startsWith('views/')) {
    return (
      <Draggable uri={uri}>
        {renderComponent()}
      </Draggable>
    );
  }

  return renderComponent();
}

export default ComponentRenderer;
