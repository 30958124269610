import { useEffect, useRef, useState } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution';

const theme = {
  base: 'vs-dark', 
  inherit: true,
  rules: [],
  colors: {
    'editor.background': '#080808',
  },
};

monaco.editor.defineTheme('space-dark', theme);

export default function CodeEditor({ value, onChange, language = 'javascript' }) {
  const [editor, setEditor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setEditor((editor) => {
        if (editor) return editor;

        return monaco.editor.create(ref.current, {
          value: value || [
            'function () {',
            '  return "Hello, world!";',
            '}',
          ].join('\n'),
          language,
          theme: 'space-dark',
          fontSize: 12,
        });
      });
    }

    return () => editor && editor.dispose();
  }, [ref.current, language]);

  return <div className="h-full w-full" ref={ref} />;
}