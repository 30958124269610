const generateDetailViewFromModel = (model, itemId) => {
  const modelName = model.id.split('.')[1];

  const fields = model.fields.map((field) => ({
    name: 'fields/DisplayFieldLabelLeft',
    props: { field },
    dataProps: {
      value: `item.${field.name}`,
    },
  }));

  return [
    // Data Config
    {
      item: {
        model: model.id,
        getBy: { id: itemId },
      },
    },

    // Component Config
    {
      name: 'views/FullWidthView',
      props: {
        header: [{
          name: 'headers/HeaderWithActionsAndBreadcrumbs',
          props: {
            title: `${modelName} ID ${itemId}`,
            buttons: [
              { name: 'buttons/EditButton', props: { modelId: model.id, itemId } },
            ],
          },
        }],
        content: [{
          name: 'views/OneColumnFields',
          props: {
            fields,
          },
        }],
      },
    },
  ];
};

export default generateDetailViewFromModel;
