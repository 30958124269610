class RpcApiExecutor {
  constructor(serviceUri, context = null) {
    this.serviceUri = serviceUri;
    this.context = context;
  }

  hasVersion(path) {
    const segments = path.split(".");
    return segments && segments[segments.length - 1].match(/^v(\d+)$/);
  }

  validatePath(path) {
    const pathSegments = path.split(".");

    if (pathSegments.length < 3) {
      throw new Error(
        `Invalid call '${path}'. Call must contain at least 3 segments: [namespace].[method].v[version].`
      );
    }

    if (!this.hasVersion(path)) {
      throw new Error(
        `Invalid call '${path}'. Call must end with the method version.`
      );
    }
  }

  buildUri(path) {
    return `${this.serviceUri}/${path}/`;
  }

  buildRequestOptions(parameters) {
    return {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(parameters),
    };
  }

  handleResponse(data) {
    if (data.status === "success") {
      return data.result;
    }

    throw new Error(data.message);
  }

  async execute(path, parameters) {
    this.validatePath(path);

    const methodUri = this.buildUri(path);
    const requestOptions = this.buildRequestOptions(parameters);
    const response = await fetch(methodUri, requestOptions);
    const data = await response.json();

    return this.handleResponse(data);
  }
}

export default class RpcService {
  constructor(serviceUri, context = null, path = null) {
    this.serviceUri = serviceUri;
    this.context = context;
    this.apiExecutor = new RpcApiExecutor(serviceUri, context);
    return this.createServiceProxy(path || "");
  }

  withContext(context, path) {
    return new RpcService(this.serviceUri, context, path);
  }

  createServiceProxy(path) {
    return new Proxy(() => { }, {
      get: (_, fieldName) => {
        if (fieldName === "withContext") {
          return (context) => this.withContext(context, path);
        }

        const newPath = path ? `${path}.${fieldName}` : fieldName;

        return this.createServiceProxy(newPath);
      },
      apply: (_, __, args) => {
        if (args.length > 1) {
          throw new Error(
            `Invalid call '${path}'. The call must have at most one parameter, which should be an object containing named parameters.`
          );
        }

        const parameters = args.length === 1 ? args[0] : {};

        return this.apiExecutor.execute(path, parameters);
      },
    });
  }
}
