import { forwardRef } from 'react';

import {
  XCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

import { XMarkIcon } from '@heroicons/react/20/solid';
import * as ToastPrimitive from '@radix-ui/react-toast';

import classNames from '../../../utils/classNames';
import usePublishHandle from './usePublishHandle';

const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';
const TYPE_INFO = 'info';
const TYPE_SUCCESS = 'success';

const ICONS = {
  [TYPE_ERROR]: XCircleIcon,
  [TYPE_WARNING]: ExclamationTriangleIcon,
  [TYPE_INFO]: InformationCircleIcon,
  [TYPE_SUCCESS]: CheckCircleIcon,
};

const ICON_COLORS = {
  [TYPE_ERROR]: 'text-red-400',
  [TYPE_WARNING]: 'text-yellow-400',
  [TYPE_INFO]: 'text-blue-400',
  [TYPE_SUCCESS]: 'text-green-400',
};

const Toast = forwardRef(({ type }, forwardedRef) => {
  const { count, title, description } = usePublishHandle(forwardedRef);
  const Icon = ICONS[type];

  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <ToastPrimitive.Root key={index} className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <Icon className={classNames("h-6 w-6", ICON_COLORS[type])} aria-hidden="true" />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <ToastPrimitive.Title className="text-sm font-medium text-gray-900">
                  {title}
                </ToastPrimitive.Title>
                <ToastPrimitive.Description className="mt-1 text-sm text-gray-500">
                  {description}
                </ToastPrimitive.Description>
              </div>
              <div className="ml-4 flex flex-shrink-0">
                <ToastPrimitive.Close className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </ToastPrimitive.Close>
              </div>
            </div>
          </div>
        </ToastPrimitive.Root>
      ))}
    </>
  );
});

Toast.ERROR = TYPE_ERROR;
Toast.WARNING = TYPE_WARNING;
Toast.INFO = TYPE_INFO;
Toast.SUCCESS = TYPE_SUCCESS;

export default Toast;
