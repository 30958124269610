import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import useModels from '../hooks/useModels';
import ViewNotFoundPage from './ViewNotFoundPage';
import ViewRenderer from '../renderer/ViewRenderer';
import Editor from '../components/editor/Editor';
import ViewBar from '../components/navigation/bars/ViewBar';

import generateCreateViewFromModel from '../renderer/generators/generateCreateViewFromModel';
import generateListViewFromModel from '../renderer/generators/generateListViewFromModel';
import generateDetailViewFromModel from '../renderer/generators/generateDetailViewFromModel';
import generateUpdateViewFromModel from '../renderer/generators/generateUpdateViewFromModel';

const findModelByRoute = (models, route) => {
  const routeSegments = route.split('/');
  const modelId = `${routeSegments[0]}.${routeSegments[1]}`;

  return models.find(m => m.id.toLowerCase() === modelId);
};

const getItemIdFromRoute = (route) => {
  return parseInt(route.split('/')[2]);
};

const getActionFromRoute = (route) => {
  const parts = route.split('/');
  const action = parts[parts.length - 1];

  if (['create', 'update'].includes(action)) {
    return action;
  }

  return null;
};

export default function ViewPage() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { models } = useModels();
  const route = params['*'];
  const [editMode, setEditMode] = useState(Boolean(searchParams.get('edit')));

  console.info(`Loading admin panel with route '${route}'...`);

  if (!models) {
    return null;
  }

  // TODO: Try to resolve a user-created panel first.
  const matchingModel = findModelByRoute(models, route);

  if (!matchingModel) {
    console.error(`Admin panel with route '${route}' not found`);
    return <ViewNotFoundPage />;
  }

  const itemId = getItemIdFromRoute(route);
  const action = getActionFromRoute(route);
  let viewConfig;

  if (action === 'create') {
    viewConfig = generateCreateViewFromModel(matchingModel);
  } else if (action === 'update') {
    viewConfig = generateUpdateViewFromModel(matchingModel, itemId);
  } else if (!isNaN(itemId)) {
    viewConfig = generateDetailViewFromModel(matchingModel, itemId);
  } else {
    viewConfig = generateListViewFromModel(matchingModel);
  }

  return (
    <div className="min-h-dvh flex flex-col items-stretch">
      <ViewBar
        editMode={editMode}
        onClickEdit={() => setEditMode(true)}
        onClickSave={() => setEditMode(false)}
        onCancelSave={() => setEditMode(false)}
      />

      <div className="flex-1 relative">
        <div className="absolute right-0 left-0 top-0 bottom-0 overflow-auto">
          <div className="min-h-full min-w-full flex">
            {editMode ? (
              <Editor
                dataConfig={viewConfig[0]}
                componentConfig={viewConfig[1]}
              />
            ) : (
              <ViewRenderer
                dataConfig={viewConfig[0]}
                componentConfig={viewConfig[1]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
