import { createSlice } from '@reduxjs/toolkit';
import initialNodes from './initialNodes';

const initialState = {
  nodes: initialNodes,
  selectedNodeId: null,
};

const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    addNode(state, action) {
      state.nodes.push(action.payload);
    },
    deleteNode(state, action) {
      state.nodes = state.nodes.filter((node) => (
        node.id !== action.payload.id
      ));
    },
    setSelectedNodeId(state, action) {
      state.selectedNodeId = action.payload;
    },
    removeRelation(state, action) {
      state.nodes = state.nodes.map((node) => {
        if (node.id === action.payload.sourceId) {
          return {
            ...node,
            relations: node.relations.filter((relation) => relation !== action.payload.targetId),
          };
        }
        return node;
      });
    },
  },
});

export const { addNode, deleteNode, setSelectedNodeId, removeRelation } = graphSlice.actions;
export default graphSlice;
