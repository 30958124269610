const initialObjects = [
    // System Management
    {
        id: "system-command-execute-1",
        name: "system.command.execute",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    // Node Management
    {
        id: "system-node-list-1",
        name: "system.node.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-node-update-1",
        name: "system.node.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-node-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-node-register-1",
        name: "system.node.register",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-node-list-1", "system-node-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-node-deregister-1",
        name: "system.node.deregister",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-node-list-1", "system-node-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-node-status-1",
        name: "system.node.status",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-node-list-1"],
        created_at: new Date().toISOString(),
    },
    // Task Management
    {
        id: "system-task-queue-create-1",
        name: "system.task.queue.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-delete-1",
        name: "system.task.queue.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-get-1",
        name: "system.task.queue.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-list-1",
        name: "system.task.queue.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-update-1",
        name: "system.task.queue.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-task-create-1",
        name: "system.task.queue.task.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-task-take-1",
        name: "system.task.queue.task.take",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-queue-task-complete-1",
        name: "system.task.queue.task.complete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-create-1",
        name: "system.task.worker.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-delete-1",
        name: "system.task.worker.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-get-1",
        name: "system.task.worker.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-list-1",
        name: "system.task.worker.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-update-1",
        name: "system.task.worker.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-assign-1",
        name: "system.task.worker.assign",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-worker-complete-1",
        name: "system.task.worker.complete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-create-1",
        name: "system.task.schedule.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-delete-1",
        name: "system.task.schedule.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-get-1",
        name: "system.task.schedule.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-list-1",
        name: "system.task.schedule.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-update-1",
        name: "system.task.schedule.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-task-schedule-execute-1",
        name: "system.task.schedule.execute",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // API Management
    {
        id: "system-api-create-1",
        name: "system.api.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-list-1",
        name: "system.api.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-get-1",
        name: "system.api.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-update-1",
        name: "system.api.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-delete-1",
        name: "system.api.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // API Execution Management
    {
        id: "system-api-execution-create-1",
        name: "system.api.execution.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-execution-list-1",
        name: "system.api.execution.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-api-execution-get-1",
        name: "system.api.execution.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    // HTTP Request Management
    {
        id: "system-http-request-create-1",
        name: "system.http.request.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-http-request-list-1",
        name: "system.http.request.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-http-request-get-1",
        name: "system.http.request.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-http-request-update-1",
        name: "system.http.request.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-http-request-delete-1",
        name: "system.http.request.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // System Health
    {
        id: "system-health-ping-1",
        name: "system.health.ping",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-health-status-1",
        name: "system.health.status",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-log-create-1"],
        created_at: new Date().toISOString(),
    },
    // Logging
    {
        id: "system-log-create-1",
        name: "system.log.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-log-list-1",
        name: "system.log.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-log-get-1",
        name: "system.log.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    // Configuration Management
    {
        id: "system-config-key-create-1",
        name: "system.config.key.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-key-delete-1",
        name: "system.config.key.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-key-get-1",
        name: "system.config.key.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-key-list-1",
        name: "system.config.key.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-key-update-1",
        name: "system.config.key.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-secret-create-1",
        name: "system.config.secret.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-secret-delete-1",
        name: "system.config.secret.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-secret-get-1",
        name: "system.config.secret.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-secret-list-1",
        name: "system.config.secret.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-config-secret-update-1",
        name: "system.config.secret.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Feature Flag Management
    {
        id: "system-feature-flag-create-1",
        name: "system.feature_flag.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-feature-flag-list-1",
        name: "system.feature_flag.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-feature-flag-get-1",
        name: "system.feature_flag.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-feature-flag-update-1",
        name: "system.feature_flag.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-feature-flag-delete-1",
        name: "system.feature_flag.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // I18N Management
    {
        id: "system-i18n-language-create-1",
        name: "system.i18n.language.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-language-delete-1",
        name: "system.i18n.language.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-language-get-1",
        name: "system.i18n.language.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-language-list-1",
        name: "system.i18n.language.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-language-update-1",
        name: "system.i18n.language.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-translation-create-1",
        name: "system.i18n.translation.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "system-i18n-language-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-translation-delete-1",
        name: "system.i18n.translation.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-translation-get-1",
        name: "system.i18n.translation.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-translation-list-1",
        name: "system.i18n.translation.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-translation-update-1",
        name: "system.i18n.translation.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-localization-create-1",
        name: "system.i18n.localization.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "system-i18n-language-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-localization-delete-1",
        name: "system.i18n.localization.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-localization-get-1",
        name: "system.i18n.localization.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-localization-list-1",
        name: "system.i18n.localization.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-localization-update-1",
        name: "system.i18n.localization.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-region-create-1",
        name: "system.i18n.region.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-region-delete-1",
        name: "system.i18n.region.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-region-get-1",
        name: "system.i18n.region.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-region-list-1",
        name: "system.i18n.region.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-i18n-region-update-1",
        name: "system.i18n.region.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // AI Management
    {
        id: "system-ai-model-create-1",
        name: "system.ai.model.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-delete-1",
        name: "system.ai.model.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-get-1",
        name: "system.ai.model.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-list-1",
        name: "system.ai.model.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-update-1",
        name: "system.ai.model.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-inference-create-1",
        name: "system.ai.model.inference.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-ai-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-model-inference-get-1",
        name: "system.ai.model.inference.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["system-ai-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-create-1",
        name: "system.ai.agent.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-delete-1",
        name: "system.ai.agent.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-get-1",
        name: "system.ai.agent.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-list-1",
        name: "system.ai.agent.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-update-1",
        name: "system.ai.agent.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-create-1",
        name: "system.ai.agent.conversation.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-delete-1",
        name: "system.ai.agent.conversation.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-get-1",
        name: "system.ai.agent.conversation.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-list-1",
        name: "system.ai.agent.conversation.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-update-1",
        name: "system.ai.agent.conversation.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-message-send-1",
        name: "system.ai.agent.conversation.message.send",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-ai-agent-conversation-message-receive-1",
        name: "system.ai.agent.conversation.message.receive",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    // File System Management
    {
        id: "system-fs-directory-create-1",
        name: "system.fs.directory.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-directory-delete-1",
        name: "system.fs.directory.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-directory-get-1",
        name: "system.fs.directory.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-directory-list-1",
        name: "system.fs.directory.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-directory-update-1",
        name: "system.fs.directory.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-create-1",
        name: "system.fs.file.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-delete-1",
        name: "system.fs.file.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-get-1",
        name: "system.fs.file.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-list-1",
        name: "system.fs.file.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-update-1",
        name: "system.fs.file.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-upload-1",
        name: "system.fs.file.upload",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-fs-file-download-1",
        name: "system.fs.file.download",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    // Backup and Restore
    {
        id: "system-backup-create-1",
        name: "system.backup.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-backup-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-backup-restore-1",
        name: "system.backup.restore",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-backup-restore-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-backup-list-1",
        name: "system.backup.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-backup-list-1"],
        created_at: new Date().toISOString(),
    },
    // Messaging Management
    {
        id: "system-messaging-message-send-1",
        name: "system.messaging.message.send",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-message-delete-1",
        name: "system.messaging.message.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-message-get-1",
        name: "system.messaging.message.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-message-list-1",
        name: "system.messaging.message.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-message-update-1",
        name: "system.messaging.message.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-template-create-1",
        name: "system.messaging.template.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-template-delete-1",
        name: "system.messaging.template.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-template-get-1",
        name: "system.messaging.template.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-template-list-1",
        name: "system.messaging.template.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-template-update-1",
        name: "system.messaging.template.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-provider-create-1",
        name: "system.messaging.provider.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-provider-delete-1",
        name: "system.messaging.provider.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-provider-get-1",
        name: "system.messaging.provider.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-provider-list-1",
        name: "system.messaging.provider.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-provider-update-1",
        name: "system.messaging.provider.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-create-1",
        name: "system.messaging.project.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-delete-1",
        name: "system.messaging.project.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-get-1",
        name: "system.messaging.project.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-list-1",
        name: "system.messaging.project.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-update-1",
        name: "system.messaging.project.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-inbox-get-1",
        name: "system.messaging.project.inbox.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-inbox-list-1",
        name: "system.messaging.project.inbox.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "system-messaging-project-inbox-delete-1",
        name: "system.messaging.project.inbox.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    // Maintenance Mode
    {
        id: "system-stop-1",
        name: "system.stop",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    // User Management
    {
        id: "user-create-1",
        name: "user.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-get-1",
        name: "user.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-list-1",
        name: "user.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-update-1",
        name: "user.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-delete-1",
        name: "user.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // User Auth Management
    {
        id: "user-auth-login-1",
        name: "user.auth.login",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1", "user-session-start-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-auth-impersonate-1",
        name: "user.auth.impersonate",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-auth-logout-1",
        name: "user.auth.logout",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-session-end-1"],
        created_at: new Date().toISOString(),
    },
    // User Session Management
    {
        id: "user-session-start-1",
        name: "user.session.start",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-session-end-1",
        name: "user.session.end",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-session-get-1",
        name: "user.session.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-session-list-1",
        name: "user.session.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-session-revoke-1",
        name: "user.session.revoke",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // User Identity Management
    {
        id: "user-identity-create-1",
        name: "user.identity.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-delete-1",
        name: "user.identity.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-get-1",
        name: "user.identity.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-list-1",
        name: "user.identity.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-update-1",
        name: "user.identity.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-reset-request-1",
        name: "user.identity.reset_request",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-notification-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-reset-1",
        name: "user.identity.reset",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-identity-verify-1",
        name: "user.identity.verify",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // User Credential Management
    {
        id: "user-credential-create-1",
        name: "user.credential.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-credential-delete-1",
        name: "user.credential.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-credential-get-1",
        name: "user.credential.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-credential-list-1",
        name: "user.credential.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-credential-update-1",
        name: "user.credential.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-credential-verify-1",
        name: "user.credential.verify",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-session-start-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    // User Attribute Management
    {
        id: "user-attribute-create-1",
        name: "user.attribute.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-attribute-delete-1",
        name: "user.attribute.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-attribute-get-1",
        name: "user.attribute.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-attribute-list-1",
        name: "user.attribute.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-attribute-update-1",
        name: "user.attribute.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-attribute-schema-list-1",
        name: "user.attribute.schema.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    // User Notification Management
    {
        id: "user-notification-create-1",
        name: "user.notification.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-notification-delete-1",
        name: "user.notification.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1", "data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-notification-get-1",
        name: "user.notification.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-notification-list-1",
        name: "user.notification.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-notification-update-1",
        name: "user.notification.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Role Management
    {
        id: "role-create-1",
        name: "role.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "role-list-1",
        name: "role.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "role-delete-1",
        name: "role.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "role-get-1",
        name: "role.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "role-update-1",
        name: "role.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Permission Management
    {
        id: "permission-create-1",
        name: "permission.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "permission-list-1",
        name: "permission.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "permission-delete-1",
        name: "permission.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "permission-get-1",
        name: "permission.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "permission-update-1",
        name: "permission.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // User Role and Permission Management
    {
        id: "user-role-assign-1",
        name: "user.role.assign",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["role-get-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-role-revoke-1",
        name: "user.role.revoke",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["role-get-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-role-get-1",
        name: "user.role.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["role-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-permission-grant-1",
        name: "user.permission.grant",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["permission-get-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-permission-revoke-1",
        name: "user.permission.revoke",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["permission-get-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "user-permission-get-1",
        name: "user.permission.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["permission-get-1"],
        created_at: new Date().toISOString(),
    },
    // MFA
    {
        id: "auth-mfa-enable-1",
        name: "auth.mfa.enable",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-credential-update-1", "user-notification-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-disable-1",
        name: "auth.mfa.disable",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-credential-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-setup-1",
        name: "auth.mfa.setup",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-credential-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-verify-1",
        name: "auth.mfa.verify",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    // MFA Policy Management
    {
        id: "auth-mfa-policy-create-1",
        name: "auth.mfa.policy.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-policy-update-1",
        name: "auth.mfa.policy.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-policy-delete-1",
        name: "auth.mfa.policy.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-policy-get-1",
        name: "auth.mfa.policy.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-mfa-policy-list-1",
        name: "auth.mfa.policy.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    // Temporary Token Management
    {
        id: "auth-token-create-1",
        name: "auth.token.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-token-consume-1",
        name: "auth.token.consume",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-token-get-1",
        name: "auth.token.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-token-list-1",
        name: "auth.token.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-token-invalidate-1",
        name: "auth.token.invalidate",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Security Policy Management
    {
        id: "auth-policy-create-1",
        name: "auth.policy.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-policy-delete-1",
        name: "auth.policy.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-policy-get-1",
        name: "auth.policy.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-policy-list-1",
        name: "auth.policy.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-policy-update-1",
        name: "auth.policy.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Authentication Rate Limiting Management
    {
        id: "auth-rate-limit-set-1",
        name: "auth.rate_limit.set",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1", "data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-rate-limit-get-1",
        name: "auth.rate_limit.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-rate-limit-list-1",
        name: "auth.rate_limit.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-rate-limit-verify-1",
        name: "auth.rate_limit.verify",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "auth-rate-limit-delete-1",
        name: "auth.rate_limit.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // Data Management
    {
        id: "data-agent-create-1",
        name: "data.agent.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-delete-1",
        name: "data.agent.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-get-1",
        name: "data.agent.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-list-1",
        name: "data.agent.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-update-1",
        name: "data.agent.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-assign-channel-1",
        name: "data.agent.assign_channel",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-remove-channel-1",
        name: "data.agent.remove_channel",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-subscription-get-1",
        name: "data.agent.subscription.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-subscribe-1",
        name: "data.agent.subscribe",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-unsubscribe-1",
        name: "data.agent.unsubscribe",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-message-send-1",
        name: "data.agent.message.send",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-agent-message-receive-1",
        name: "data.agent.message.receive",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Channel Management
    {
        id: "data-channel-create-1",
        name: "data.channel.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-channel-delete-1",
        name: "data.channel.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-channel-message-get-1",
        name: "data.channel.message.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-channel-list-1",
        name: "data.channel.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-channel-message-reply-1",
        name: "data.channel.message.reply",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-channel-message-send-1",
        name: "data.channel.message.send",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Model Management
    {
        id: "data-model-create-1",
        name: "data.model.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-schema-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-delete-1",
        name: "data.model.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-schema-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-get-1",
        name: "data.model.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-schema-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-list-1",
        name: "data.model.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-schema-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-update-1",
        name: "data.model.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-schema-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-schema-create-1",
        name: "data.model.schema.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-schema-list-1",
        name: "data.model.schema.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-schema-update-1",
        name: "data.model.schema.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-schema-delete-1",
        name: "data.model.schema.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-model-schema-get-1",
        name: "data.model.schema.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: [],
        created_at: new Date().toISOString(),
    },
    // Data Backup Management
    {
        id: "data-backup-create-1",
        name: "data.backup.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-restore-1",
        name: "data.backup.restore",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-get-1",
        name: "data.backup.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-list-1",
        name: "data.backup.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-delete-1",
        name: "data.backup.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-plan-create-1",
        name: "data.backup.plan.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-plan-list-1",
        name: "data.backup.plan.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-plan-get-1",
        name: "data.backup.plan.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-plan-update-1",
        name: "data.backup.plan.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-backup-plan-delete-1",
        name: "data.backup.plan.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // Data Shard Management
    {
        id: "data-shard-create-1",
        name: "data.shard.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-shard-get-1",
        name: "data.shard.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-shard-list-1",
        name: "data.shard.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-shard-update-1",
        name: "data.shard.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "data-shard-delete-1",
        name: "data.shard.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    // Organization Management
    {
        id: "organization-create-1",
        name: "organization.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-delete-1",
        name: "organization.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-get-1",
        name: "organization.get",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-get-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-list-1",
        name: "organization.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-update-1",
        name: "organization.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-member-add-1",
        name: "organization.member.add",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-identity-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "organization-member-remove-1",
        name: "organization.member.remove",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["user-identity-update-1"],
        created_at: new Date().toISOString(),
    },
    // Application Management
    {
        id: "application-create-1",
        name: "application.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-list-1",
        name: "application.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-update-1",
        name: "application.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // View Management
    {
        id: "application-view-create-1",
        name: "application.view.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-view-list-1",
        name: "application.view.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-view-update-1",
        name: "application.view.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    // Data Connection Management
    {
        id: "application-data-connection-create-1",
        name: "application.data_connection.create",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-create-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-data-connection-list-1",
        name: "application.data_connection.list",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-list-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-data-connection-update-1",
        name: "application.data_connection.update",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-data-connection-delete-1",
        name: "application.data_connection.delete",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-delete-1"],
        created_at: new Date().toISOString(),
    },
    {
        id: "application-data-connection-sync-1",
        name: "application.data_connection.sync",
        version: "1.0.0",
        type: "function.v1.0.0",
        data: {},
        relations: ["data-model-update-1"],
        created_at: new Date().toISOString(),
    },
];

function transformWithNamespaces(objects) {
    const namespaceMap = new Map();
    const idToObjectMap = new Map();

    objects.forEach(obj => {
        idToObjectMap.set(obj.id, obj);
    });

    const getNamespaceId = (namespaceName) => `namespace-${namespaceName.replace(/\./g, '-')}`;

    const createNamespaceObject = (namespaceName) => {
        const id = getNamespaceId(namespaceName);
        const parts = namespaceName.split('.');
        const parentNamespace = parts.slice(0, -1).join('.');
        return {
            id,
            name: namespaceName,
            version: "1.0.0",
            type: "namespace.v1.0.0",
            data: {},
            relations: parentNamespace ? [getNamespaceId(parentNamespace)] : [],
            created_at: new Date().toISOString(),
        };
    };

    objects.forEach(obj => {
        const nameParts = obj.name.split('.');
        for (let i = 1; i <= nameParts.length - 1; i++) {
            const namespace = nameParts.slice(0, i).join('.');
            if (!namespaceMap.has(namespace)) {
                namespaceMap.set(namespace, createNamespaceObject(namespace));
            }
        }
    });

    const namespaceObjects = Array.from(namespaceMap.values());

    const transformedObjects = objects.map(obj => {
        const nameParts = obj.name.split('.');
        if (nameParts.length < 2) {
            console.warn(`Object "${obj.id}" has a name with less than two segments. Skipping namespace assignment.`);
            return obj; // Skip if there's no namespace
        }

        const namespace = nameParts.slice(0, -1).join('.');
        const namespaceId = getNamespaceId(namespace);

        if (!namespaceMap.has(namespace)) {
            namespaceMap.set(namespace, createNamespaceObject(namespace));
            namespaceObjects.push(namespaceMap.get(namespace));
        }

        return {
            ...obj,
            relations: [namespaceId],
        };
    });

    return [...transformedObjects, ...namespaceObjects];
}

export default transformWithNamespaces(initialObjects);