import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { CircleStackIcon, DocumentPlusIcon, FaceFrownIcon } from '@heroicons/react/24/outline';

import useSearch from '../../../hooks/useSearch';
import classNames from '../../../utils/classNames';
import useModels from '../../../hooks/useModels';
import useFavorites from '../../../hooks/useFavorites';
import SearchFooter from './SearchFooter';

const ACTION_DEFAULT = 'default';
const ACTION_CREATE = 'create';

const ACTIONS = [
  ACTION_CREATE,
];

const ACTION_ICONS = {
  [ACTION_DEFAULT]: CircleStackIcon,
  [ACTION_CREATE]: DocumentPlusIcon,
};

const ACTION_TEXT = {
  [ACTION_DEFAULT]: 'Go to…',
  [ACTION_CREATE]: 'Create…',
};

const parseQuery = (query) => {
  const tokens = query.split(' ');
  let action = ACTION_DEFAULT;
  let keyword = null;

  if (!tokens.length) {
    return { action, keyword };
  }

  if (ACTIONS.includes(tokens[0].toLowerCase())) {
    action = tokens.shift().toLowerCase();
  }

  if (!tokens.length) {
    return { action, keyword };
  }

  keyword = tokens.shift().toLowerCase();

  return { action, keyword };
};

export default function SearchDialog() {
  const navigate = useNavigate();
  const { query, setQuery, isOpen, closeSearch } = useSearch();
  const { models } = useModels();
  const { favorites } = useFavorites();
  const { action, keyword } = parseQuery(query);

  if (!models) {
    return null;
  }

  const filteredModels =
    !keyword
      ? []
      : models.filter((model) => {
          return model.id.toLowerCase().includes(keyword);
        });

  const ActionIcon = ACTION_ICONS[action];

  const handleSelect = (model) => {
    let modelPath;

    if (typeof model === 'string') {
      modelPath = model;
    } else {
      const parts = model.id.split('.');
      modelPath = `/x/${parts[0]}/${parts[1].toLowerCase()}`;
    }

    if (action === ACTION_CREATE) {
      modelPath += '/create';
    }

    navigate(modelPath);
    closeSearch();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog className="relative z-10" onClose={closeSearch}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={handleSelect}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                  />
                </div>

                {filteredModels.length > 0 && (
                  <Combobox.Options
                    static
                    as="ul"
                    className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
                  >
                    <li className="p-2">
                      <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
                        Models
                      </h2>
                      <ul className="text-sm text-gray-700">
                        {filteredModels.map((model) => (
                          <Combobox.Option
                            as="li"
                            key={model.id}
                            value={model}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                active && 'bg-yellow-400 text-black'
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <ActionIcon
                                  className={classNames('h-6 w-6 flex-none', active ? 'text-black' : 'text-gray-400')}
                                  aria-hidden="true"
                                />
                                <span className="ml-3 flex-auto truncate">{model.id}</span>
                                {active && (
                                  <span className="ml-3 flex-none text-yellow-800">
                                    {ACTION_TEXT[action]}
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </ul>
                    </li>
                  </Combobox.Options>
                )}

                {!keyword && (
                  <Combobox.Options
                    static
                    as="ul"
                    className="max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto"
                  >
                    <li className="p-2">
                      <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-500">
                        Favorites
                      </h2>
                      <ul className="text-sm text-gray-700">
                        {favorites.map((favorite) => (
                          <Combobox.Option
                            as="li"
                            key={favorite}
                            value={favorite}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                active && 'bg-yellow-400 text-black'
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <ActionIcon
                                  className={classNames('h-6 w-6 flex-none', active ? 'text-black' : 'text-gray-400')}
                                  aria-hidden="true"
                                />
                                <span className="ml-3 flex-auto truncate">{favorite}</span>
                                {active && (
                                  <span className="ml-3 flex-none text-yellow-800">
                                    {ACTION_TEXT[action]}
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </ul>
                    </li>
                  </Combobox.Options>
                )}

                {keyword && filteredModels.length === 0 && (
                  <div className="px-6 py-14 text-center sm:px-14">
                    <FaceFrownIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 text-sm text-gray-900">
                      We couldn't find any models with that term. Please try again.
                    </p>
                  </div>
                )}

                <SearchFooter action={action} />
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
