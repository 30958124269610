import { useState } from 'react';
import deepCopy from '../../utils/deepCopy';
import ViewRenderer from '../../renderer/ViewRenderer';
import EditorContext from '../../renderer/contexts/EditorContext';
import EditorPanel from './EditorPanel';

const getConfigByURI = (uri, componentConfig) => {
  const parts = uri.split('.');

  return parts.reduce((location, part) => {
    if (part.startsWith('#')) {
      if (Array.isArray(location)) {
        const index = parseInt(part.replace('#', ''));
        return location[index];
      } else {
        return location;
      }
    }

    if (location.name === part) {
      return location;
    }

    if (location.props && location.props[part]) {
      return location.props[part];
    }

    return location;
  }, componentConfig);
};

const getParentConfigByURI = (uri, componentConfig) => {
  const keys = uri.split('.');
  const lastIndex = keys.length - 1;
  const lastKey = keys[lastIndex];
  
  const parseKey = (key) => {
    if (key.startsWith('#')) {
      return parseInt(key.replace('#', ''));
    }

    return key;
  };

  const parent = keys.reduce((location, key, keyIndex) => {
    const parsedKey = parseKey(key);

    if (keyIndex === lastIndex) {
      return location;
    }

    if (typeof parsedKey === 'number') {
      if (Array.isArray(location)) {
        return location[parsedKey];
      } else {
        return location;
      }
    }

    if (location.name === key) {
      return location;
    }

    if (location.props && location.props[key]) {
      return location.props[key];
    }

    return location;
  }, componentConfig);

  return [parent, parseKey(lastKey)];
};

const Editor = ({ dataConfig, componentConfig }) => {
  const [
    localComponentConfig,
    setLocalComponentConfig,
  ] = useState(componentConfig);

  const [uriToEdit, setURIToEdit] = useState();

  const addComponent = (uri, config) => {
    console.log(`%c ADD %c ${uri}`, 'background: #222; color: #bada55', 'color: #fefefe;');
    setLocalComponentConfig((prevComponentConfig) => {
      const newComponentConfig = deepCopy(prevComponentConfig);
      const [parent, index] = getParentConfigByURI(uri, newComponentConfig);

      if (!isNaN(index)) {
        parent.splice(index, 0, deepCopy(config));
      } else {
        parent.props[index] = parent.props[index] || [];
        parent.props[index].push(deepCopy(config));
      }

      return newComponentConfig;
    });
  };

  const removeComponent = (uri) => {
    console.log(`%c REMOVE %c ${uri}`, 'background: #222; color: #ec2020', 'color: #fefefe;');
    setLocalComponentConfig((prevComponentConfig) => {
      const newComponentConfig = deepCopy(prevComponentConfig);
      const [parent, index] = getParentConfigByURI(uri, newComponentConfig);
  
      if (!isNaN(index)) {
        parent.splice(index, 1);
      } else {
        parent.props[index] = [];
      }

      return newComponentConfig;
    });
  };

  const updateComponentProp = (uri, propertyName, newValue) => {
    console.log(`%c UPDATE %c ${uri} ${propertyName}`, 'background: #222; color: #0000FF', 'color: #fefefe;');
    setLocalComponentConfig((prevComponentConfig) => {
      const newComponentConfig = deepCopy(prevComponentConfig);
      const config = getConfigByURI(uri, newComponentConfig);

      config.props[propertyName] = deepCopy(newValue);

      return newComponentConfig;
    });
  };

  const updateComponentDataProps = (uri, dataProps) => {
    console.log(`%c UPDATE DATA %c ${uri}`, 'background: #222; color: #0000FF', 'color: #fefefe;');
    setLocalComponentConfig((prevComponentConfig) => {
      const newComponentConfig = deepCopy(prevComponentConfig);
      const config = getConfigByURI(uri, newComponentConfig);

      config.dataProps = {
        ...config.dataProps,
        ...dataProps,
      };

      return newComponentConfig;
    });
  };

  const editorContext = {
    getConfigByURI: (uri) => getConfigByURI(uri, localComponentConfig),
    addComponent,
    removeComponent,
    updateComponentProp,
    updateComponentDataProps,
    editURI: (uri) => setURIToEdit(uri),
    uriToEdit,
    dataConfig,
  };

  return (
    <EditorContext.Provider value={editorContext}>
      <div className="w-full flex items-stretch">
        <div className="flex-1 relative">
          <div className="absolute right-0 left-0 top-0 bottom-0 overflow-auto flex">
            <ViewRenderer
              dataConfig={dataConfig}
              componentConfig={localComponentConfig}
              editMode={true}
            />
          </div>
        </div>
        <div className="flex w-96 relative bg-white border-l border-gray-200">
          <div className="absolute right-0 left-0 top-0 bottom-0 overflow-auto flex">
            <EditorPanel />
          </div>
        </div>
      </div>
    </EditorContext.Provider>
  );
};

export default Editor;
