import { useState } from 'react';

export default function useDebouncedCallback(callback, delay) {
  const [_, setTimer] = useState(null);

  return (...args) => {
    setTimer((timer) => {
      if (timer) {
        clearTimeout(timer);
      }

      return setTimeout(() => {
        callback(...args);
      }, delay);
    });
  };
}
