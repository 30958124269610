import { createSlice } from '@reduxjs/toolkit';
import NetworkStatus from '../enums/NetworkStatus';

import {
  endCurrentSession,
  getCurrentSession,
  loginWithUsernamePassword,
} from './actions';

const initialState = {
  status: NetworkStatus.IDLE,
  hasSession: false,
  user: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // endCurrentSession reducers.
      .addCase(endCurrentSession.fulfilled, (state) => {
        delete state.user;
        state.status = NetworkStatus.FULFILLED;
        state.hasSession = false;
      })
      .addCase(endCurrentSession.rejected, (state) => {
        state.status = NetworkStatus.REJECTED;
      })

      // getCurrentSession reducers.
      .addCase(getCurrentSession.pending, (state) => {
        state.status = NetworkStatus.PENDING;
      })
      .addCase(getCurrentSession.fulfilled, (state, action) => {
        state.status = NetworkStatus.FULFILLED;

        if (action.payload) {
          state.hasSession = true;
          state.user = action.payload.user;
        } else {
          state.hasSession = false;
          state.user = undefined;
        }
      })
      .addCase(getCurrentSession.rejected, (state) => {
        state.status = NetworkStatus.REJECTED;
        state.hasSession = false;
        state.user = undefined;
      })

      // loginWithUsernamePassword reducers.
      .addCase(loginWithUsernamePassword.pending, (state) => {
        state.status = NetworkStatus.PENDING;
      })
      .addCase(loginWithUsernamePassword.fulfilled, (state, action) => {
        state.status = NetworkStatus.FULFILLED;
        state.hasSession = true;
        state.user = action.payload.user;
      })
      .addCase(loginWithUsernamePassword.rejected, (state) => {
        state.status = NetworkStatus.REJECTED;
        state.hasSession = false;
      })
  }
});

export default authSlice;
