import CreateButton from './buttons/CreateButton';
import EditButton from './buttons/EditButton';
import CancelEditButton from './buttons/CancelEditButton';
import SubmitButton from './buttons/SubmitButton';

import HeaderWithActionsAndBreadcrumbs from './headers/HeaderWithActionsAndBreadcrumbs';

import ListInCard from './lists/ListInCard';
import SimpleList from './lists/SimpleList';

import FormFieldLabelLeft from './fields/FormFieldLabelLeft';
import DisplayField from './fields/DisplayField';
import DisplayFieldLabelLeft from './fields/DisplayFieldLabelLeft';

import Form from './forms/Form';

import FullWidthView from './views/FullWidthView';
import OneColumnFields from './views/OneColumnFields';
import TwoColumnFields from './views/TwoColumnFields';

export default {
  'buttons/CreateButton': CreateButton,
  'buttons/EditButton': EditButton,
  'buttons/CancelEditButton': CancelEditButton,
  'buttons/SubmitButton': SubmitButton,

  'headers/HeaderWithActionsAndBreadcrumbs': HeaderWithActionsAndBreadcrumbs,

  'lists/ListInCard': ListInCard,
  'lists/SimpleList': SimpleList,

  'fields/FormFieldLabelLeft': FormFieldLabelLeft,
  'fields/DisplayField': DisplayField,
  'fields/DisplayFieldLabelLeft': DisplayFieldLabelLeft,

  'forms/Form': Form,

  'views/FullWidthView': FullWidthView,
  'views/OneColumnFields': OneColumnFields,
  'views/TwoColumnFields': TwoColumnFields,
};
