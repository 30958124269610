import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

function createDataKey(config) {
  const configHash = JSON.stringify(config)
    .split("")
    .reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) & 0xffffffff, 0)
    .toString(16);

  return `data#${configHash}`;
}

const ActionType = {
  UPDATE_FIELD: "UPDATE_FIELD",
  SET_DATA: "SET_DATA",
  BEGIN_SAVE: "BEGIN_SAVE",
  END_SAVE: "END_SAVE",
  UPDATE_BOUND_FIELD: "UPDATE_BOUND_FIELD",
};

const initialState = {
  data: {},
  boundData: {},
  unsavedChanges: {},
  activeSaves: {},
};

const dataProviders = {
  model: {
    load: async (config, filters) => {
      const modelId = config.id;

      try {
        const schemas = await window.api.model.get_schemas.v1();
        const schema = schemas.items.find((x) => x.id === modelId);

        const itemsResult = await window.api.model.get_items.v1({
          model_id: modelId,
          filters: filters,
        });

        return {
          config,
          schema,
          items: itemsResult.items,
          pagination: itemsResult.pagination,
        };
      } catch (error) {
        console.error("Error loading data:", error);
        return { config, schema: {}, items: [], pagination: {} };
      }
    },
    persist: async (dataEntry, updatedData) => {
      try {
        return window.api.model.update_items.v1({
          model_id: dataEntry.schema.id,
          items: updatedData,
        });
      } catch (error) {
        console.error("Error persisting data:", error);
      }
    },
  },
};

function getDataProvider(type) {
  const dataProvider = dataProviders[type];

  if (!dataProvider) {
    throw new Error(`Unsupported data type: ${type}`);
  }

  return dataProvider;
}

function normalizeBindKey(key) {
  const prefix = "$data.";
  return key.startsWith(prefix) ? key.slice(prefix.length) : key;
}

function resolveBoundValues(data, boundData) {
  if (!data) {
    return data;
  }

  try {
    return Object.entries(data).reduce((resolved, [key, value]) => {
      if (typeof value === "object" && "$bind" in value) {
        const normalizedKey = normalizeBindKey(value.$bind);
        resolved[key] = boundData[normalizedKey] || "";
      } else {
        resolved[key] = resolveBoundValues(value, boundData);
      }
      return resolved;
    }, {});
  } catch (error) {
    console.error("Error resolving data:", error);
    return {};
  }
}

function reducer(state, action) {
  switch (action.type) {
    case ActionType.UPDATE_FIELD: {
      const { dataKey, field, value, index } = action.payload;
      const dataEntry = state.data[dataKey];

      if (!dataEntry || !dataEntry.items) {
        return state;
      }

      const updatedItems = [...dataEntry.items];
      updatedItems[index] = { ...updatedItems[index], [field]: value };

      const newUnsavedChanges = { ...state.unsavedChanges };
      newUnsavedChanges[dataKey] = newUnsavedChanges[dataKey] || {};
      newUnsavedChanges[dataKey][index] =
        newUnsavedChanges[dataKey][index] || {};
      newUnsavedChanges[dataKey][index][field] = true;

      return {
        ...state,
        data: {
          ...state.data,
          [dataKey]: { ...dataEntry, items: updatedItems },
        },
        unsavedChanges: newUnsavedChanges,
      };
    }

    case ActionType.SET_DATA: {
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };
    }

    case ActionType.BEGIN_SAVE: {
      return {
        ...state,
        activeSaves: { ...state.activeSaves, [action.payload.dataKey]: true },
      };
    }

    case ActionType.END_SAVE: {
      const { dataKey } = action.payload;
      const newActiveSaves = { ...state.activeSaves };
      const newUnsavedChanges = { ...state.unsavedChanges };

      delete newActiveSaves[dataKey];
      delete newUnsavedChanges[dataKey];

      return {
        ...state,
        activeSaves: newActiveSaves,
        unsavedChanges: newUnsavedChanges,
      };
    }

    case ActionType.UPDATE_BOUND_FIELD: {
      const { field, value } = action.payload;
      return {
        ...state,
        boundData: { ...state.boundData, [field]: value || "" },
      };
    }

    default:
      return state;
  }
}

const GlobalStateContext = createContext();

function GlobalStateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateField = (payload) =>
    dispatch({
      type: ActionType.UPDATE_FIELD,
      payload,
    });

  const setData = (data) =>
    dispatch({
      type: ActionType.SET_DATA,
      payload: { data },
    });

  const saveChanges = async (dataKey) => {
    if (state.activeSaves[dataKey]) {
      return;
    }

    const dataEntry = state.data[dataKey];

    const dataProvider = getDataProvider(dataEntry.config.type);

    dispatch({
      type: ActionType.BEGIN_SAVE,
      payload: { dataKey },
    });

    const updatedItems = dataEntry.items
      .map((item, index) => {
        const changedFields = state.unsavedChanges[dataKey]?.[index];

        if (!changedFields) {
          return null;
        }

        const updatedItem = {};
        for (const field in changedFields) {
          updatedItem[field] = item[field];
        }
        updatedItem.id = item.id;

        return updatedItem;
      })
      .filter(Boolean);

    try {
      await dataProvider.persist(dataEntry, updatedItems);
    } finally {
      dispatch({
        type: ActionType.END_SAVE,
        payload: { dataKey },
      });
    }
  };

  function updateBoundField(field, value) {
    const normalizedField = normalizeBindKey(field);
    dispatch({
      type: ActionType.UPDATE_BOUND_FIELD,
      payload: { field: normalizedField, value: value || "" },
    });
  }

  return (
    <GlobalStateContext.Provider
      value={{ state, updateField, setData, saveChanges, updateBoundField }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}

function useGlobalState() {
  return useContext(GlobalStateContext);
}

async function createDataEntry(dataConfig, boundData) {
  const { type, filters } = dataConfig;

  const dataProvider = getDataProvider(type);
  const resolvedFilters = resolveBoundValues(filters, boundData);

  return dataProvider.load(dataConfig, resolvedFilters);
}

const EditableTable = memo(function EditableTable({
  columns,
  data,
  onInputChange,
}) {
  return (
    <div className="relative w-full">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns.map((column) => (
              <th key={column.name} scope="col" className="px-6 py-3">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              tabIndex={0}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              {columns.map((column) => (
                <td key={column.name} className="px-6 py-4">
                  <input
                    type={column.type || "text"}
                    value={item[column.name] || ""}
                    onChange={(e) =>
                      onInputChange(index, column.name, e.target.value)
                    }
                    disabled={column.primaryKey}
                    className="block w-full p-1 border border-gray-300 rounded-md bg-gray-100 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

const SaveChangesButton = ({ dataKey, enabled }) => {
  const { saveChanges } = useGlobalState();

  const handleClick = () => saveChanges(dataKey);

  return (
    <div className="flex justify-end mt-4">
      <button
        onClick={handleClick}
        disabled={!enabled}
        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${!enabled && "opacity-50 cursor-not-allowed"
          }`}
      >
        Save Changes
      </button>
    </div>
  );
};

function EditableModelTable({ dataKey }) {
  const { state, updateField } = useGlobalState();
  const data = state.data[dataKey];
  const hasUnsavedChanges = Boolean(state.unsavedChanges[dataKey]);

  if (!data || !data.schema || !data.items.length) {
    return null;
  }

  const columns = data.schema.fields.map((field) => ({
    name: field.name,
    label: (field.label || field.name).replace(/_/g, " "),
    primaryKey: field.primary_key,
    type: field.type || "text",
  }));

  const handleInputChange = (index, field, value) =>
    updateField({ dataKey, field, value, index });

  return (
    <>
      <EditableTable
        columns={columns}
        data={data.items}
        onInputChange={handleInputChange}
      />
      <SaveChangesButton dataKey={dataKey} enabled={hasUnsavedChanges} />
    </>
  );
}

function InputField({ dataKey, title }) {
  const { state, updateBoundField } = useGlobalState();
  const value = state.boundData[dataKey] || "";

  const handleInputChange = (e) => updateBoundField(dataKey, e.target.value);

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 dark:text-white mb-1">
        {title}
      </label>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        className="block w-full p-2 border border-gray-300 rounded-md bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-white"
      />
    </div>
  );
}

const viewComponents = {
  EditableModelTable,
  InputField,
};

function withDataLoading(Component) {
  return function DataLoadingWrapper({
    dataConfig,
    props = {},
    ...otherProps
  }) {
    const { state, setData } = useGlobalState();
    const [dataKey, setDataKey] = useState(() => createDataKey(dataConfig));
    const [currentBoundFields, setCurrentBoundFields] = useState([]);
    const previousBoundDataRef = useRef({});

    function resolveBoundFields(config) {
      const boundFields = [];
      function findBoundFields(obj) {
        if (typeof obj === "object" && obj !== null) {
          for (const key in obj) {
            if (obj[key]?.$bind) {
              boundFields.push(normalizeBindKey(obj[key].$bind));
            } else {
              findBoundFields(obj[key]);
            }
          }
        }
      }
      findBoundFields(config);
      return boundFields;
    }

    const loadData = useCallback(async () => {
      try {
        const dataEntry = await createDataEntry(dataConfig, state.boundData);
        const newDataKey = createDataKey(dataEntry);
        setDataKey(newDataKey);
        setData({
          [newDataKey]: dataEntry,
        });
      } catch (error) {
        console.error(`Error loading data by key: ${error}`);
      }
    }, [dataConfig, setData, state.boundData]);

    useEffect(() => {
      if (dataConfig.type === "data") {
        setDataKey(dataConfig.id);
      } else if (!(dataKey in state.data)) {
        loadData();
      }

      const boundFields = resolveBoundFields(dataConfig);
      setCurrentBoundFields(boundFields);
    }, [dataConfig, dataKey, loadData, state.data]);

    useEffect(() => {
      const shouldReload = currentBoundFields.some(
        (field) =>
          state.boundData[field] !== previousBoundDataRef.current[field]
      );

      if (shouldReload) {
        loadData();
      }

      previousBoundDataRef.current = { ...state.boundData };
    }, [currentBoundFields, loadData, state.boundData]);

    return dataKey ? (
      <Component {...otherProps} dataKey={dataKey} {...props} />
    ) : null;
  };
}

function ConfiguredView({ config }) {
  return (
    <div>
      {config.map((componentConfig, index) => {
        const Component = withDataLoading(viewComponents[componentConfig.type]);
        return (
          <Component
            key={index}
            dataConfig={componentConfig.data}
            props={componentConfig.props}
          />
        );
      })}
    </div>
  );
}

const viewConfig = [
  {
    type: "InputField",
    props: {
      title: "Name",
    },
    data: {
      type: "data",
      id: "name",
    },
  },
  {
    type: "InputField",
    props: {
      title: "Codename",
    },
    data: {
      type: "data",
      id: "codename",
    },
  },
  {
    type: "EditableModelTable",
    data: {
      type: "model",
      id: "auth.User",
    },
  },
  {
    type: "EditableModelTable",
    data: {
      type: "model",
      id: "auth.Permission",
      filters: {
        name: {
          $contains: {
            $bind: "$data.name",
          },
        },
        codename: {
          $contains: {
            $bind: "$data.codename",
          },
        },
      },
    },
  },
];

function ConfiguredViewPage() {
  return (
    <GlobalStateProvider>
      <ConfiguredView config={viewConfig} />
    </GlobalStateProvider>
  );
}

export default ConfiguredViewPage;
