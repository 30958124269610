import LogoSmall from './LogoSmall';

const PoweredBy = ({ className }) => {
  return (
    <div className={className}>
      <span className="text-sm text-gray-400">
        Powered by
      </span>
      <LogoSmall />
    </div>
  );
};

export default PoweredBy;
