import { Suspense } from 'react';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import Alert from '../components/feedback/alerts/Alert';

import AlertWithLink from '../components/feedback/alerts/AlertWithLink';
import AlertWithButtons from '../components/feedback/alerts/AlertWithButtons';
import LoadingView from '../components/feedback/spinners/LoadingView';

export default function LogoutPage() {
  const data = useLoaderData();
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Suspense fallback={<LoadingView text="Signing out..." />}>
          <Await
            resolve={data.sessionEnded}
            errorElement={
              <AlertWithButtons
                type={Alert.ERROR}
                title="Unable to sign you out"
                message="Refresh this page to try again."
                buttons={[
                  { title: 'Try again', onClick: () => window.location.reload() },
                  { title: 'Go back', onClick: () => navigate('/') },
                ]}
              />
            }
          >
            <AlertWithLink
              type={Alert.SUCCESS}
              message="You've been successfully signed out"
              linkText="Log in again"
              linkHref="/login"
            />
          </Await>
        </Suspense>
      </div>
    </div>
  )
};
