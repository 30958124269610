import { Code as CodePrimitive } from '@radix-ui/themes';

const Code = ({ children, ...props }) => {
  return (
    <CodePrimitive {...props}>
      {children}
    </CodePrimitive>
  );
};

export default Code;
