import classNames from '../../../utils/classNames';

const SearchFooter = ({ action }) => {
  return (
    <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
      Type{' '}
      <kbd
        className={classNames(
          'mx-1 px-1 flex h-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
          action === 'create' ? 'border-yellow-600 text-yellow-600' : 'border-gray-400 text-gray-900'
        )}
      >
        create
      </kbd>{' '}
      <span className="hidden sm:inline">to create an item.</span>
    </div>
  );
};

export default SearchFooter;
