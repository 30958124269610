import { useEffect, useState } from 'react';
import { Box, Select } from '@radix-ui/themes';

import { data } from '../../../api';
import useModels from '../../../hooks/useModels';
import Alert from '../../../components/feedback/alerts/Alert';
import Spinner from '../../../components/feedback/spinners/Spinner';

function RelationshipFieldSelect({ relatedField, form, value }) {
  const { models } = useModels();
  const [items, setItems] = useState();
  const [error, setError] = useState();
  const field = relatedField;

  const handleOnChange = (newValue) => {
    form.setFieldValue(field.name, newValue);
  };

  useEffect(() => {
    const loadItems = async () => {
      try {
        const result = await data.model.list.v1({
            model_id: field.related_model,
        });

        setItems(result.items);
      } catch (error) {
        console.error(`Error when loading related items '${field.name}':`, error);
        setError(error);
      }
    };

    if (field) {
      loadItems();
    }
  }, [field]);

  if (error) {
    return (
      <Box maxWidth="450px">
        <Alert
          type={Alert.ERROR}
          title="Error when loading related items"
          message={error.message}
        />
      </Box>
    );
  }

  if (!items || !models) {
    return <Spinner />;
  }

  const relatedModel = models.find(model => model.id === field.related_model);

  return (
    <Select.Root name={field.name} value={value} onValueChange={handleOnChange}>
      <Select.Trigger placeholder="None" />
      <Select.Content>
        <Select.Group>
          <Select.Item value={null} className="italic">
            None
          </Select.Item>
        </Select.Group>
        <Select.Separator />
        <Select.Group>
          <Select.Label>{relatedModel.id}s:</Select.Label>
          {items.map((item) => (
            <Select.Item value={item.id}>
              {item.name}
            </Select.Item>
          ))}
        </Select.Group>
      </Select.Content>
    </Select.Root>
  );
}

export default RelationshipFieldSelect;
