import { createAsyncThunk } from '@reduxjs/toolkit';

const addFavorite = createAsyncThunk('favorites/addFavorite', async (path) => {
  const favorites = JSON.parse(window.localStorage.getItem('favorites')) || [];

  favorites.push(path);

  window.localStorage.setItem(
    'favorites',
    JSON.stringify(favorites),
  );
});

export default addFavorite;
