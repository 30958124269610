import { useLocation } from 'react-router-dom';
import EmptyStateDashed from '../components/feedback/empty/EmptyStateDashed';

export default function ViewNotFoundPage() {
  const location = useLocation();

  return (
    <main className="grid min-h-dvh place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 -my-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          View not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, we couldn’t find the view you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <EmptyStateDashed title={`Create a new view for ${location.pathname}`} />
        </div>
      </div>
    </main>
  );
}
