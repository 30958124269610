import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedNodeId } from '../../state/graph/slice';
import useGraph from './useGraph';

const useSelectedNode = () => {
  const dispatch = useDispatch();
  const selectedNodeId = useSelector((state) => state.graph.selectedNodeId);
  const { nodes } = useGraph();

  const selectedNode = useMemo(() => (
    nodes.find((node) => node.id === selectedNodeId)
  ), [nodes, selectedNodeId]);

  return {
    selectedNodeId,
    selectedNode,
    selectNode: (node) => {
      dispatch(setSelectedNodeId(node ? node.id : null));
    },
  };
};

export default useSelectedNode;
