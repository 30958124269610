import { useState } from 'react';
import { Tooltip, Dialog, Button, Flex, Kbd } from '@radix-ui/themes';
import { FullscreenIcon, OrbitIcon, CircleHelpIcon } from 'lucide-react';
import { useHotkeys } from 'react-hotkeys-hook';

export default function SpaceControls({ onZoomToFit, onOrbitClick, isOrbiting = false }) {
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  useHotkeys('ctrl+z', onZoomToFit, { preventDefault: true });
  useHotkeys('ctrl+o', onOrbitClick, { preventDefault: true });

  const handleHelpClick = () => {
    setIsHelpOpen(true);
  };

  return (
    <>
      <Dialog.Root open={isHelpOpen} onOpenChange={setIsHelpOpen}>
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>Help</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            <div className="flex flex-col gap-2">
              <div>Left-click and drag to rotate.</div>
              <div>Right-click and drag to pan.</div>
              <div>Scroll to zoom.</div>
              <div><Kbd>CTRL+Z</Kbd> to zoom graph to fit viewport.</div>
              <div><Kbd>CTRL+O</Kbd> to automatically orbit the graph.</div>
            </div>
          </Dialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="soft" color="gray">
                OK
              </Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>

      <div className="absolute right-0 bottom-0 flex items-center justify-end p-4 z-99">
        <div className="bg-background3 rounded-sm">
          <Tooltip content="Help">
            <button className="p-2 hover:bg-background2 rounded-sm" onClick={handleHelpClick}>
              <CircleHelpIcon size={14} color="white" />
            </button>
          </Tooltip>
          <Tooltip content={isOrbiting ? 'Stop Orbiting ^O' : 'Orbit ^O'}>
            <button className="p-2 hover:bg-background2 rounded-sm" onClick={onOrbitClick}>
              <OrbitIcon size={14} color={isOrbiting ? 'lime' : 'white'} />
            </button>
          </Tooltip>
          <Tooltip content="Zoom to Fit ^Z">
            <button className="p-2 hover:bg-background2 rounded-sm" onClick={onZoomToFit}>
              <FullscreenIcon size={14} />
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
