const Logo = ({ className = 'size-24' }) => {
  return (
    <img
      src="/img/logos/spaceportal-logo-test-001-upscale-transparent-cropped.png"
      className={className}
      alt="SpacePortal"
    />
  );
};

export default Logo;
