import DisplayField from './DisplayField';

function DisplayFieldLabelLeft({ field, value }) {
  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        {field.verbose_name}
      </dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <DisplayField field={field} value={value} />
      </dd>
    </div>
  );
}

DisplayFieldLabelLeft.editableProps = {
  field: {
    title: 'Field Mapping',
    type: 'MODEL_FIELD',
    options: {
      dataProp: 'value',
    },
  },
  fieldComponent: {
    title: 'Field Component',
    type: 'COMPONENT',
    options: {
      componentTypes: ['fields'],
    },
  },
};

export default DisplayFieldLabelLeft;
