import {
  XCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid';

const TYPE_ERROR = 'error';
const TYPE_WARNING = 'warning';
const TYPE_INFO = 'info';
const TYPE_SUCCESS = 'success';

export const BG_COLORS = {
  [TYPE_ERROR]: 'bg-red-50',
  [TYPE_WARNING]: 'bg-yellow-50',
  [TYPE_INFO]: 'bg-blue-50',
  [TYPE_SUCCESS]: 'bg-green-50',
};

export const ICON_COLORS = {
  [TYPE_ERROR]: 'text-red-400',
  [TYPE_WARNING]: 'text-yellow-400',
  [TYPE_INFO]: 'text-blue-400',
  [TYPE_SUCCESS]: 'text-green-400',
};

export const TITLE_COLORS = {
  [TYPE_ERROR]: 'text-red-800',
  [TYPE_WARNING]: 'text-yellow-800',
  [TYPE_INFO]: 'text-blue-800',
  [TYPE_SUCCESS]: 'text-green-800',
};

export const MESSAGE_COLORS = {
  [TYPE_ERROR]: 'text-red-700',
  [TYPE_WARNING]: 'text-yellow-700',
  [TYPE_INFO]: 'text-blue-700',
  [TYPE_SUCCESS]: 'text-green-700',
};

export const ICONS = {
  [TYPE_ERROR]: XCircleIcon,
  [TYPE_WARNING]: ExclamationTriangleIcon,
  [TYPE_INFO]: InformationCircleIcon,
  [TYPE_SUCCESS]: CheckCircleIcon,
};

function AlertBase({ type, children }) {
  const Icon = ICONS[type];

  return (
    <div className={`rounded-md p-4 ${BG_COLORS[type]}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${ICON_COLORS[type]}`} aria-hidden="true" />
        </div>
        {children}
      </div>
    </div>
  );
};

AlertBase.ERROR = TYPE_ERROR;
AlertBase.WARNING = TYPE_WARNING;
AlertBase.INFO = TYPE_INFO;
AlertBase.SUCCESS = TYPE_SUCCESS;

export default AlertBase;
