import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../../api';

let promise;

const getCurrentSession = createAsyncThunk('auth/getCurrentSession', async () => {
  if (promise) {
    return promise;
  }

  promise = auth.session.get.v1().finally(() => {
    promise = undefined;
  });

  return promise;
});

export default getCurrentSession;
