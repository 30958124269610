import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import * as Toast from '@radix-ui/react-toast';

import { Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';

import './index.css';

import ToastViewport from './components/feedback/toasts/ToastViewport';
import reportWebVitals from './reportWebVitals';
import store from './state/store';
import router from './router';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Theme accentColor="lime" className="min-h-full" appearance="dark" hasBackground={false}>
      <Toast.Provider>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>

        <ToastViewport />
      </Toast.Provider>
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
