import { Code, Link } from '@radix-ui/themes';
import useSelectedNode from '../../../hooks/graph/useSelectedNode';
import NodeIcon from '../icons/NodeIcon';

export default function NodeLabelLink({ node }) {
  const { selectNode } = useSelectedNode();

  return (
    <Link
      color="gray"
      className="flex items-center gap-2 cursor-pointer"
      onClick={() => selectNode(node)}
    >
      <NodeIcon node={node} />
      <Code variant="ghost" size="2" className="text-ellipsis overflow-hidden whitespace-nowrap">
        {node.name}
      </Code>
    </Link>
  );
}
