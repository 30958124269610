import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';

import ForceGraph3D from '../../../libs/ForceGraph3D/ForceGraph3D';
import Logo from '../../logos/Logo';
import TopSpaceBar from '../bars/TopSpaceBar';
import SpaceToolbar from '../toolbar/SpaceToolbar';
import SpaceControls from '../controls/SpaceControls';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import useGraph from '../../../hooks/graph/useGraph';
import useSelectedNode from '../../../hooks/graph/useSelectedNode';

const namespaceColors = {
  'system.': '#A18072',
  'user.': '#7CE2FE',
  'role.': '#27A383',
  'permission.': '#15A494',
  'auth.': '#01A2C7',
  'data.': '#8E4EC5',
  'organization.': '#D6409F',
  'application.': '#BDEE63',
}

export const getNodeColorByNamespace = (node) => {
  const namespace = Object.keys(namespaceColors)
    .find((namespace) => node.name.startsWith(namespace));

  if (namespace) {
    return namespaceColors[namespace];
  }

  return '#20D5A2';
};

const getGraphData = (nodes, links) => {
  return {
    nodes: nodes.map(node => ({ ...node })),
    links: links.map(link => ({ ...link }))
  };
};

const getLinkDistance = (link) => {
  const { target, source } = link;
  const sourceNamespaces = source.name.split('.');
  const targetNamespaces = target.name.split('.');

  for (let i = 0; i < Math.max(sourceNamespaces.length, targetNamespaces.length); i++) {
    if (sourceNamespaces[i] !== targetNamespaces[i]) {
      switch (i) {
        case 0:
          return 100;
        case 1:
          return 50;
        default:
          return 10;
      }
    }
  }

  return 100;
};

/**
 * WORKAROUND:
 * The graph should ideally be stored as state in the component
 * to allow for multiple graphs to be created and updated.
 * 
 * However, in strict dev mode, the component is rendered twice,
 * causing the graph to be created twice which crashes the app.
 * So store the graph in a global variable to avoid it from being
 * recreated twice.
 */
let graph = null;

export default function ForceGraph() {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [filter, setFilter] = useState('');
  const [isOrbiting, setIsOrbiting] = useState(false);
  const { nodes, links } = useGraph();
  const { selectedNode, selectNode } = useSelectedNode();

  const onSearch = useCallback((event) => {
    if (event.key === 'Enter') {
      graph.zoomToFit(500, 20, (node) => node.name.includes(filter));
      return;
    }

    if (event.keyCode === 27) {
      setFilter('');
      return;
    }

    if (event.target.value !== filter) {
      setFilter(event.target.value);
    }
  }, [filter]);

  const debouncedOnSearch = useDebouncedCallback(onSearch, 100);

  const getNodeColor = useCallback((node) => {
    if (selectedNode && selectedNode.id === node.id) {
      return '#E5484D';
    }

    if (!filter) {
      return getNodeColorByNamespace(node);
    }

    if (node.name.includes(filter)) {
      return 'red';
    }

    return 'rgba(255, 255, 255, 0.1)';
  }, [filter, selectedNode]);

  const getLinkColor = useCallback((link) => {
    if (!filter || link.target.name.includes(filter) || link.source.name.includes(filter)) {
      return 'rgb(255, 255, 255)';
    }

    return 'rgba(100, 100, 100, 1)';
  }, [filter]);

  // Highlight the selected node with a ring and a label.
  const getNodeThreeObject = useCallback((node) => {
    if (!selectedNode || selectedNode.id !== node.id) {
      return null;
    }

    const nodeElement = document.createElement('div');
    const ringElement = document.createElement('div');
    const textElement = document.createElement('div');

    nodeElement.appendChild(ringElement);
    ringElement.appendChild(textElement);

    nodeElement.style.display = 'flex';
    nodeElement.style.justifyContent = 'center';
    nodeElement.style.alignItems = 'center';

    ringElement.style.width = '150px';
    ringElement.style.height = '150px';
    ringElement.style.borderRadius = '50%';
    ringElement.style.border = '1px solid rgba(255, 255, 255, 0.9)';

    textElement.textContent = node.name;
    textElement.style.position = 'absolute';
    textElement.style.top = '50%';
    textElement.style.left = '50%';
    textElement.style.marginTop = '-25px';
    textElement.style.transform = 'translate(-50%, -50%)';
    textElement.style.fontSize = '14px';
    textElement.style.fontWeight = 'bold';
    textElement.style.textAlign = 'center';
    textElement.style.padding = '4px 8px';
    textElement.style.borderRadius = '4px';
    textElement.style.backgroundColor = 'rgba(0,0,0,0.75)';
    textElement.style.userSelect = 'none';

    return new CSS2DObject(nodeElement);
  }, [selectedNode]);

  useLayoutEffect(() => {
    const { width, height } = ref.current.getBoundingClientRect();
    setWidth(width);
    setHeight(height);
  }, [ref.current]);

  useEffect(() => {
    if (ref.current) {
      graph = new ForceGraph3D({
        extraRenderers: [new CSS2DRenderer()]
      })(ref.current)
        .graphData(getGraphData(nodes, links))
        .width(width)
        .height(height)
        .nodeLabel('name')
        .nodeResolution(24)
        .linkDirectionalArrowLength(3.5)
        .linkDirectionalArrowRelPos(1)
        .linkCurvature(0.01)
        .linkColor(getLinkColor)
        .backgroundColor('#080808')
        .onNodeClick((node, event) => {
          selectNode(node);
        })
        .nodeColor(getNodeColor)
        .enableNodeDrag(false)
        .d3VelocityDecay(0.5)
        .nodeThreeObject(getNodeThreeObject)
        .nodeThreeObjectExtend(true)
        .linkDirectionalParticles(d => Math.random() * 10)
        .linkDirectionalParticleSpeed(d => Math.random() * 0.01)
        .showNavInfo(false);

      const linkForce = graph
        .d3Force('link')
        .distance(getLinkDistance);

      graph.numDimensions(3);
    }
  }, [ref.current]);

  // Update graph dimensions when window resizes.
  useEffect(() => {
    if (graph && width && height) {
      graph
        .width(width)
        .height(height);
    }
  }, [width, height]);

  // Update node/link colors when filter or selected node changes.
  useEffect(() => {
    if (graph) {
      graph.nodeColor(getNodeColor);
      graph.linkColor(getLinkColor);
      graph.nodeThreeObject(getNodeThreeObject);
    }
  }, [filter, selectedNode]);

  // Update graph data when nodes or links change.
  useEffect(() => {
    if (graph) {
      graph.graphData(getGraphData(nodes, links));
    }
  }, [nodes, links]);

  // Orbit the graph when the user clicks the orbit button.
  useEffect(() => {
    const distance = 1400;

    if (isOrbiting) {
      let angle = 0;

      graph
        .enableNavigationControls(false)
        .cameraPosition({ z: distance });

      const interval = setInterval(() => {
        graph.cameraPosition({
          x: distance * Math.sin(angle),
          z: distance * Math.cos(angle),
        });

        angle += Math.PI / 300;
      }, 10);

      return () => {
        clearInterval(interval);
      };
    } else {
      graph.enableNavigationControls(true);
    }
  }, [isOrbiting]);

  return (
    <>
      <div ref={ref} className="w-full h-full overflow-auto" />
      <TopSpaceBar onSearch={debouncedOnSearch} />
      <SpaceToolbar />
      <SpaceControls
        onZoomToFit={() => graph.zoomToFit(400, 10)}
        onOrbitClick={() => setIsOrbiting(!isOrbiting)}
        isOrbiting={isOrbiting}
      />
      <div className="absolute top-0 left-0 p-4 z-100">
        <Logo className="size-10" />
      </div>
    </>
  );
}
