import { useNavigate } from 'react-router-dom';
import Button from './Button';

const HrefButton = ({ className, href, children, ...props }) => {
  const navigate = useNavigate();

  return (
    <Button
      className={className}
      onClick={() => navigate(href)}
      {...props}
    >
      {children}
    </Button>
  );
};

export default HrefButton;
