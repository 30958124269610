const LogoSmall = ({ className = 'size-16' }) => {
  return (
    <img
      src="/img/logos/spaceportal-logo-medium-size.png"
      className={className}
      alt="SpacePortal"
    />
  );
};

export default LogoSmall;
