import { Link } from 'react-router-dom';
import AlertBase, { MESSAGE_COLORS } from './AlertBase';

const LINK_HOVER_COLORS = {
  [AlertBase.ERROR]: 'hover:text-red-600',
  [AlertBase.WARNING]: 'hover:text-yellow-600',
  [AlertBase.INFO]: 'hover:text-blue-600',
  [AlertBase.SUCCESS]: 'hover:text-green-600',
};

function AlertWithLink({ type, message, linkText, linkHref }) {
  return (
    <AlertBase type={type}>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className={`text-sm ${MESSAGE_COLORS[type]}`}>
          {message}
        </p>
        <p className="mt-3 flex items-center text-sm md:ml-6 md:mt-0">
          <Link
            to={linkHref}
            className={`whitespace-nowrap font-medium ${MESSAGE_COLORS[type]} ${LINK_HOVER_COLORS[type]}`}
          >
            {linkText}
            <span aria-hidden="true"> &rarr;</span>
          </Link>
        </p>
      </div>
    </AlertBase>
  );
};

AlertWithLink.ERROR = AlertBase.ERROR;
AlertWithLink.WARNING = AlertBase.WARNING;
AlertWithLink.INFO = AlertBase.INFO;
AlertWithLink.SUCCESS = AlertBase.SUCCESS;

export default AlertWithLink;
