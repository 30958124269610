import { Outlet } from 'react-router';
import SearchDialog from '../components/navigation/SearchDialog';

function Root() {
  return (
    <div className="h-screen w-full overflow-hidden border-t-[0.5px] border-t-separator1">
      <div className="h-full overflow-auto">
        <Outlet />
      </div>
      <SearchDialog />
    </div>
  );
}

export default Root;
