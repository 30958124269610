import { useLocation } from 'react-router-dom';
import { IconButton, DropdownMenu, Tooltip } from '@radix-ui/themes';
import {
  DotsHorizontalIcon,
  CheckIcon,
  Pencil1Icon,
  StarIcon,
  StarFilledIcon,
} from '@radix-ui/react-icons';

import useFavorites from '../../../hooks/useFavorites';
import Button from '../../buttons/Button';
import Breadcrumbs from '../Breadcrumbs';

const ViewBar = ({ editMode, onClickEdit, onClickSave, onCancelSave }) => {
  const location = useLocation();

  const {
    favorites,
    addFavorite,
    removeFavorite,
    isFavorite,
  } = useFavorites();

  const handleAddToFavorites = () => {
    addFavorite(location.pathname);
  };

  const handleRemoveFromFavorites = () => {
    removeFavorite(location.pathname);
  };

  return (
    <div className="bg-white border-b-[0.5px] border-gray-200 z-50">
      <div className="px-2 py-2 flex flex-1 self-stretch items-center justify-between">
        <Breadcrumbs />

        {editMode ? (
          <div className="flex items-center">
            <Button variant="ghost" onClick={onCancelSave} className="mr-2">
              Cancel
            </Button>
            <Button variant="solid" color="green" onClick={onClickSave}>
              <CheckIcon /> Save
            </Button>
          </div>
        ) : (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <IconButton size="1" variant="ghost" color="gray" className="mr-1">
                  <Tooltip content="Panel Options">
                    <DotsHorizontalIcon width="18" height="18" />
                  </Tooltip>
                </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item onClick={onClickEdit}>
                <Pencil1Icon /> Edit Panel...
              </DropdownMenu.Item>
              {isFavorite(location.pathname) ? (
                <DropdownMenu.Item onClick={handleRemoveFromFavorites}>
                  <StarIcon /> Remove from Favorites
                </DropdownMenu.Item>
              ) : (
                <DropdownMenu.Item onClick={handleAddToFavorites}>
                  <StarFilledIcon /> Add to Favorites
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )}
      </div>
    </div>
  );
};

export default ViewBar;
