import HrefButton from "../../../components/buttons/HrefButton";

export default function EditButton({ modelId, itemId }) {
  const parts = modelId.split('.');
  const updatePath = `/x/${parts[0]}/${parts[1].toLowerCase()}/${itemId}/update`;

  return (
    <HrefButton href={updatePath}>
      Edit
    </HrefButton>
  );
};
