import { ContextMenu } from '@radix-ui/themes';

const RightClickMenu = ({ onDelete, onEdit }) => {
  return (
    <ContextMenu.Content>
      <ContextMenu.Item shortcut="⌘ E" onClick={onEdit}>Edit</ContextMenu.Item>
      <ContextMenu.Item shortcut="⌘ D">Duplicate</ContextMenu.Item>
      <ContextMenu.Separator />
      <ContextMenu.Item shortcut="⌘ ⌫" color="red" onClick={onDelete}>
        Delete
      </ContextMenu.Item>
    </ContextMenu.Content>
  );
};

export default RightClickMenu;
