import { defer, redirect } from 'react-router-dom';
import { endCurrentSession, getCurrentSession } from '../state/auth/actions';
import NetworkStatus from '../state/enums/NetworkStatus';
import store from '../state/store';
import ConfiguredViewPage from './ConfiguredViewPage';
import ErrorPage from './ErrorPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import ModelsPage from './ModelsPage';
import Root from './Root';
import ViewPage from './ViewPage';
import SpacePage from './SpacePage';
import CirclePage from './CirclePage';

const routes = [
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: async () => {
      await store.dispatch(getCurrentSession());

      const { hasSession, status } = store.getState().auth;

      if (status === NetworkStatus.FULFILLED && !hasSession) {
        throw redirect('/login');
      }

      return null;
    },
    children: [
      {
        path: '/',
        element: <SpacePage />,
      },
      {
        path: '/circle',
        element: <CirclePage />,
      },
      {
        path: '/configured-view',
        element: <ConfiguredViewPage />,
      },
      {
        path: '/models',
        element: <ModelsPage />,
      },
      {
        path: '/x/*',
        element: <ViewPage />,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
    loader: async () => {
      await store.dispatch(getCurrentSession());

      if (store.getState().auth.hasSession) {
        throw redirect('/');
      }

      return null;
    },
  },
  {
    path: "/logout",
    element: <LogoutPage />,
    loader: async () => {
      return defer({
        sessionEnded: store.dispatch(endCurrentSession()),
      });
    },
  },
];

export default routes;
