import { createAsyncThunk } from '@reduxjs/toolkit';
import { user } from '../../../api';

const loginWithUsernamePassword = createAsyncThunk(
  'auth/loginWithUsernamePassword',
  async ({ username, password }) => {
    const result = await user.auth.login.v1({
      identity: {
        type: 'username',
        value: username,
      },
      credential: {
        type: 'password',
        value: password,
      },
    });

    if (result && result.is_valid) {
      return result;
    }

    throw new Error('Invalid username or password.');
  }
);

export default loginWithUsernamePassword;
