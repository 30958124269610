import Spinner from './Spinner';

const LoadingView = ({ text }) => (
  <div className="flex items-center justify-center h-full w-full">
    <div className="flex-shrink-0 mr-3">
      <Spinner />
    </div>
    <span className="text-sm text-gray-600">
      {text}
    </span>
  </div>
);

export default LoadingView;
