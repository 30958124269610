import { useState } from 'react';
import { Flex, IconButton } from '@radix-ui/themes';
import { CopyIcon } from '@radix-ui/react-icons';
import Code from './Code';

const CodeWithCopy = ({ value, ...props }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Flex align="center" gap="2">
      <Code variant="ghost" {...props}>
        {value}
      </Code>
      <IconButton
        size="1"
        aria-label="Copy value"
        color="gray"
        variant="ghost"
        onClick={handleClick}
      >
        {copied ? (
          <span className="text-xs font-medium">
            Copied!
          </span>
        ) : (
          <CopyIcon />
        )}
      </IconButton>
    </Flex>
  );
};

export default CodeWithCopy;
