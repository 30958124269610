import { Field, ErrorMessage } from 'formik';
import RelationshipFieldSelect from './RelationshipFieldSelect';

function FormFieldLabelLeft(field) {
  const { name, verbose_name, type } = field;

  return (
    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
      <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
        {verbose_name}
      </label>
      <div className="mt-2 sm:col-span-2 sm:mt-0">
        {type === 'relationship' ? (
          <Field
            name={name}
            component={RelationshipFieldSelect}
            relatedField={field}
          />
        ) : (
          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
            <Field
              type={type}
              name={name}
              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        )}

        <ErrorMessage name={name} component="div" />
      </div>
    </div>
  );
}

export default FormFieldLabelLeft;
