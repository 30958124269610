import { useMemo, useCallback } from 'react';
import { Tabs, Text, Badge } from '@radix-ui/themes';
import { MousePointerClick } from 'lucide-react';

import useGraph from '../../../../hooks/graph/useGraph';
import useSelectedNode from '../../../../hooks/graph/useSelectedNode';
import CodeEditor from '../../../editors/CodeEditor';
import NodeLabel from '../../labels/NodeLabel';
import NodeList from '../../lists/NodeList';
import Properties from './tabs/Properties';
import OptionsMenu from './OptionsMenu';

export default function NodePanel() {
  const { nodes, removeRelation } = useGraph();
  const { selectedNode } = useSelectedNode();

  const dependencies = useMemo(() => {
    return selectedNode && nodes.filter((obj) =>
      selectedNode.relations.includes(obj.id)
    );
  }, [nodes, selectedNode]);

  const dependants = useMemo(() => {
    return selectedNode && nodes.filter((obj) =>
      obj.relations.includes(selectedNode.id)
    );
  }, [nodes, selectedNode]);

  const handleRemoveDependency = useCallback((node) => {
    removeRelation(selectedNode.id, node.id);
  }, [removeRelation, selectedNode]);

  if (!selectedNode) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <div className="flex items-center gap-2">
          <MousePointerClick size={16} />
          <Text size="2">Click on a node to edit it</Text>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full overflow-hidden border-b-[0.5px] border-b-separator1 p-2">
        <div className="flex items-center justify-between h-full">
          <NodeLabel node={selectedNode} withCopyButton />

          <div className="flex items-center justify-center gap-2">
            <Badge color="jade" variant="soft" size="1">
              {selectedNode.version}
            </Badge>
            <OptionsMenu />
          </div>
        </div>
      </div>

      <Tabs.Root defaultValue="code" className="flex flex-1 flex-col flex-grow">
        <Tabs.List size="1">
          <Tabs.Trigger value="code">Code</Tabs.Trigger>
          <Tabs.Trigger value="props">Props</Tabs.Trigger>
          <Tabs.Trigger value="dependencies">
            <span className="flex items-center gap-1">
              <div>Dependencies</div>
              <Badge size="1" color="gray">{dependencies.length}</Badge>
            </span>
          </Tabs.Trigger>
          <Tabs.Trigger value="dependants">
            <span className="flex items-center gap-1">
              <div>Dependants</div>
              <Badge size="1" color="gray">{dependants.length}</Badge>
            </span>
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value="code" className="h-full">
          <CodeEditor />
        </Tabs.Content>

        <Tabs.Content value="props" className="p-3">
          <Properties selectedNode={selectedNode} />
        </Tabs.Content>

        <Tabs.Content value="dependencies" className="relative h-full w-full">
          <div className="absolute top-0 left-0 right-0 bottom-0 overflow-scroll">
            <NodeList
              nodes={dependencies}
              noNodesText="No Dependencies"
              canDelete={true}
              onDelete={handleRemoveDependency}
            />
          </div>
        </Tabs.Content>

        <Tabs.Content value="dependants" className="relative h-full w-full">
          <div className="absolute top-0 left-0 right-0 bottom-0 overflow-scroll">
            <NodeList nodes={dependants} noNodesText="No Dependants" />
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}
